.facebook {
  width: 100%;
  padding: 0rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.fbtitle {
  width: 100%;
  display: flex;
  margin: 3rem 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.dashboard {
  margin-top: 1rem;
}

.leftMainVid{
  width: 650px;
}

.facebook h1 {
  font-size: 3rem;
  margin-top: 1rem;
  text-align: center;
  font-family: "Lora";
  color: rgb(0, 0, 0);
}

.fbtitle h1 img {
  height: 30px;
  width: 30px;
  margin: 0 1.5rem;
}

.facebook img {
  height: 30px;
  width: 30px;
}

.facebook h1 span {
  color: #FFAF00;
  font-family: "Lora";
  position: relative;
}
.facebook h1 span::after {
  content: "";
  height: 2px;
  top: 3.7rem;
  right: 1rem;
  width: 170px;
  color: #FFAF00;
  position: absolute;
  background-color: #fcb300;
}

.facebook p {
  font-size: 1.5rem;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.585);
}



.shivbg {
  height: 80vh;
  width: 100%;
 
}

.shivbg img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.btn {
  margin: 0;
  width: 100%;
  height: 15vh;
  display: flex;
  text-align: center;
  font-family: "Lora";
  align-items: center;
  font-family: "Lora";
  justify-content: center;
}
.btn h2 {
  font-size: 20px;
  font-family: "Lora";
}
.btn button {
  border: none;
  outline: none;
  display: flex;
  background: none;
  margin-left: 2rem;
  margin-top: -0.2rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.btn button  {
  display: flex;
  padding: 8px 30px;
  font-size: 1.5rem;
  border-radius: 7px;
  font-weight: bold;
  text-align: center;
  font-family: "Lora";
  align-items: center;
  font-family: "Lora, serif";
  background-color: #FFAF00;
  color: rgba(0, 0, 0, 0.662);
  justify-content: space-evenly;
}
.btn button img {
  margin-right: 7px;
}

/*--------------- responsiveness styles here------------------------------- */

@media screen and (max-width: 1300px) {
  .facebook {
    width: 100%;
    padding: 0rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
  }

  .fbtitle {
    width: 100%;
    display: flex;
    margin: 2rem 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }



  /* .rightImg {
    width: 4.5vw;
    border: none;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 0.5vw;
  } */

  .dashboard {
    margin-top: 0rem;
  }

  .facebook h1 {
    margin-top: 1rem;
    font-size: 2.2rem;
    text-align: center;
    font-family: "Lora";
    color: rgb(0, 0, 0);
  }

  .fbtitle h1 img {
    width: 30px;
    height: 30px;
    margin: 0 1.5rem;
  }

  .facebook img {
    width: 30px;
    height: 30px;
  }

  .facebook h1 span {
    color: #FFAF00;
    position: relative;
    font-family: "Lora";
  }
  .facebook h1 span::after {
    height: 2px;
    top: 2.7rem;
    right: 0rem;
    content: "";
    width: 135px;
    color: #FFAF00;
    position: absolute;
    background-color: #fcb300;
  }

  .facebook p {
    font-size: 1.5rem;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.585);
  }

  .shivbg {
    height: 80vh;
    width: 100%;
   
  }
  
  .shivbg img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .btn {
    width: 100%;
    height: 15vh;
    display: flex;
    margin: 1rem 0;
    align-items: center;
    padding-right: 9rem;
    justify-content: center;
    font-family: "Lora, serif";
  }
  .btn h2 {
    font-size: 20px;
    margin-right: 0rem;
    margin-left: -2rem;
    font-family: "Lora";
  }
  .btn button {
    border: none;
    outline: none;
    background: none;
    margin-left: 1rem;
  }

  .btn button  {
    display: flex;
    padding: 8px 30px;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    font-family: "Lora, serif";
    background-color: #fcb300;
    color: rgba(0, 0, 0, 0.662);
    justify-content: space-evenly;
  }
  .btn button img {
    margin-right: 7px;
  }
}

@media screen and (max-width: 540px) {
  .facebook {
    width: 100%;
    padding: 0;
    display: flex;
    margin-top: 1rem;
    align-items: center;
    flex-direction: column;
  }

  .dashboard {
    margin-top: 0rem;
  }

  .facebook h1 {
    font-size: 1.5rem;
    margin-top: 1rem;
    text-align: center;
    color: rgb(0, 0, 0);
    font-family: "Lora";
  }   

  .fbtitle {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .fbtitle h1 img {
    width: 15px;
    height: 15px;
    margin: 0 0.8rem;
  }

  .facebook img {
    width: 15px;
    height: 15px;
  }

  .facebook h1 span {
    color: #FFAF00;
    text-decoration: underline;
  }


  .facebook h1 span::after {
    width: 0;
    height: 0;
    content: "";
    color: transparent;
    background-color: transparent;
  }

  .facebook p {
    font-size: 1rem;
    letter-spacing: 0px;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.585);
    text-align: center;
  }

  .shivbg {
    height: 50vh;
    width: 100%;
   
  }
  
  .shivbg img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .btn {
    width: 100%;
    height: 15vh;
    margin: 1rem 0;
    display: flex;
    padding-right: 0rem;
    font-family: "Lora";
    align-items: center;
    flex-direction: column;
    margin-top: -4rem;
  }
  .btn h2 {
    font-size: 12px;
    margin-left: 0rem;
    margin-right: 0rem;
    font-family: "Lora";
  }
  .btn button {
    border: none;
    outline: none;
    background: none;
    margin-left: 0rem;
  }

  .btn button  {
    display: flex;
    font-size: 1rem;
    padding: 8px 30px;
    font-weight: bold;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    font-family: "Lora";
    background-color: #fcb300;
    justify-content: space-evenly;
    color: rgba(0, 0, 0, 0.662);
  }
  .btn button img {
    margin-right: 7px;
  }
}

/* -------------------------------------------------faceboook dashboard-------------------------------------------------- */

.FacebookDashboard {
  /* height: 80vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left {
  flex: 4;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.left img {
  width: 800px;
  height: 500px;
}

.lefttitle {
  top: 1rem;
  left: 3rem;
  position: absolute;
}

.lefttitle h6 {
  color: white;
  font-size: 1rem;
}

.lefttitle img {
  top: 20rem;
  left: 27rem;
  width: 50px;
  height: 50px;
  position: absolute;
}

.right {
  flex: 3;
  display: flex;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rightWrapper {
  width: 100%;
  display: flex;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
}

.rightImg {
  width: 8.5vw;
  border: none;
  aspect-ratio: 16/9;
  object-fit: cover;
  border-radius: 0.5vw;
}

.rightInfo {
  flex: 2;
  height: 100%;
  width: 12vw;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.rightInfo h5 {
  font-size: 0.9rem;
}

.rightInfo h4 {
  color: rgba(0, 0, 0, 0.553);
  font-size: 1rem;
  letter-spacing: normal;
  font-family: "Lora, serif";
  margin-top: 0;
}

.rightWrapper img {
  border-radius: 5px;
}
.rightImg {
  margin-right: 1rem;
}

.rightImg img {
  width: 230px;
  height: 102px;
}

/*-------------------- responsiveness----------------------------- */

@media screen and (max-width: 1300px) {
  .FacebookDashboard {
    /* height: 70vh; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
 
    margin-top: -6rem !important;
    /* padding: 0; */
    margin-bottom: 2rem !important;
  }

  .left {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* height: 100%; */
    width: 100%;

  }

  .left img {
    height: 400px;
    width: 600px;
  }

  .lefttitle {
    position: absolute;

    top: 0rem;
    left: 2rem;
  }

  .lefttitle h6 {
    color: white;
    font-size: 1rem;
  }

  .lefttitle img {
    top: 14rem;
    left: 18rem;
    position: absolute;
    height: 50px;
    width: 50px;
  }

  .leftMainVid{
    width: 57vw;
    /* margin-top: 7rem; */
    position: relative;
    top: 4rem;
  }

  .right {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-left: 1rem;

    margin-top: 8rem !important;
  }

  .rightImg {
   width: 35.5%;
   margin: 0rem 0;
  }



  .rightInfo {
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1rem;
  }

  .rightInfo h5 {
    font-size: 0.9rem;
  }

  .rightInfo h4 {
    color: rgba(0, 0, 0, 0.553);
    font-size: 1rem;
    letter-spacing: normal;
    font-family: "Lora, serif";
    margin-top: 1rem;
  }

  .rightWrapper img {
    border-radius: 5px;
  }
  .rightImg {
    margin-right: 1rem;
  }

  .rightImg img {
    width: 160px;
    height: 85px;
  }
  .org {
    margin: 2px 0 2px 0;
  }

  .rightWrapper h5 {
    font-size: 0.9rem;
    margin: 0;
  }


  .rightWrapper {
    display: flex;
    margin: 10px 0;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
  }

}

/* ---------------------------------------540px--------------------------------------------- */

@media screen and (max-width: 540px) {
  .FacebookDashboard {
    /* height: 100vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .left {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .left img {
    width: 350px;
    height: 200px;
  }

  .lefttitle {
    position: absolute;

    top: -1rem;
    left: 1rem;
  }

  .lefttitle h6 {
    color: white;
    font-size: 0.6rem;
  }

  .leftMainVid{
    width: 350px;
    /* margin-top: 7rem; */
    position: relative;
    top: 4rem;
    margin-top: 2rem;
  }

  .lefttitle img {
    /* top: 4rem; */
    top: 8rem;
    left: 10rem;
    /* position: relative; */
    position: absolute;
    height: 30px;
    width: 30px;
  }

  .right {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5rem;
    margin-left: 0;
    padding: 0 0.7rem;
    box-sizing: border-box;
    position: relative;
    top: -4rem;
  }

  .rightWrapper {
    display: flex;
    margin: 5px 0;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
  }

  .org {
    margin: 2px 0 2px 0;
  }

  .rightImg {
    flex: 1.5;
    height: 100%;
  }

  .rightInfo{
    margin-top: 2rem;
  }

  .rightInfo {
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: -1rem;
  }

  .rightInfo h4 {
    color: rgba(0, 0, 0, 0.553);
    font-size: 0.7rem;
    letter-spacing: normal;
    font-family: "Lora, serif";
    white-space: nowrap;
  }
  .rightWrapper h5 {
    font-size: 0.7rem;
    /* white-space: nowrap; */
    margin: 0;
  }
  .rightWrapper img {
    border-radius: 5px;
  }
  .rightImg {
    margin-right: 1rem;
  }
  .rightImg img {
    height: 70px;
    width: 130px;
  }
}
