.wrapper{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction:column;
    width:100%;
    padding-bottom: 7rem !important;   
}

.title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    margin-top: 4rem;
}

.title h1{
    font-family: 'Lora';
    font-size: 2.5rem;
}

.title h1 img{
    height: 25px;
    margin: 0 1rem;
}
.title p{
    color: #818181;
    font-family: Lora;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
}






.slid{
    height: 60vh;
    width: 100%;
    margin-top: 3rem;
    position: relative;
/* margin-bottom: 1rem; */
}

.sliderWrapper{

    width: 100%;
    /* margin-top: 3rem; */
}

.one {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    opacity: .8;
    transform: scale(.9);
    transition: all ease .9s;
}

.active{
    opacity: 1;
    transform: scale(1);

}

.dots{
    height: 10px;
    width: 10px;
    background-color: black;
    border-radius: 100%;
}

.dotActive{
    height: 10px;
    width: 10px;
    background-color: rgb(255, 0, 0);
    border-radius: 100%;
}


.btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 13rem;

}
.slick__dots{
    display: inline-flex !important;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.slick__dots li{
    list-style: none;
    display: flex;
    /* visibility: hidden; */
}
.slick__dots li button{
    outline: none;
    border: none;
    background-color: #FFC1B7;
    color: #FFC1B7;
    margin: 0 5px;
    border-radius: 100%;
    width: 11px;
    height: 11px;
    font-size: 0px;
cursor: pointer;
}
.slick__dots li button:hover{
    background-color: #C45B4A;
}
.slick__dots li button:focus{
    background-color: #C45B4A;
    width: 14px;
    height: 14px;
}



.btn button{
 padding: 1rem;
    outline: none;
    border: none;
    background-color: #C45B4A;
    border-radius: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px 1px rgba(196,91,74,0.5);
    font-size: 1.5rem;
cursor: pointer;
}


.btn button img{
    height: 20px;
    filter: invert(1);
}

.btn button:first-child{
    margin-left: 6rem;
}
.btn button:last-child{
    margin-right: 6rem;
}

.sliderWrapper img{
    height: 450px;
  width: 100%;
  object-fit: cover;
border-radius: 4px !important;
cursor: pointer;

}


.backgroundImg {
    height: 80vh;
    width: 100%;
    position: relative;
  top: 6rem;
  background-color: red;
     
  }
  
  .backgroundImg img{
    height: 100%;
    width: 100%;
    object-fit: cover;


    
  }


@media screen and (max-width: 1300px){
    .sliderWrapper img{
        /* height: 400px; */
        height: 100%;
      width: 100%;
  object-fit: cover;
border-radius: 5px !important;
    } 

.slid{
    height: 60vh;
    width: 100%;
    margin-top: 3rem;
    position: relative;
margin-bottom: 0rem;
}

.wrapper{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction:column;
    width:100%;
    padding-bottom: 4rem !important;   
}

}
@media screen and (max-width: 540px){

    .sliderWrapper img{
        height: 250px;
      width: 100%;
    } 

    .wrapper{
        display: flex;
        align-items: center;
        /* justify-content: center; */
        flex-direction:column;
        width:100%;
        padding-bottom: 1rem !important;
    }

    .slid{
        height: auto;
        width: 100%;
        margin-top: 3rem;
        position: relative;
    }


    .title h1{
        font-family: 'Lora';
        font-size: 1.5rem;
    }
    
    .title h1 img{
        height: 15px;
        margin: 0 1rem;
    }
    .title p{
        color: #818181;
        font-family: Lora;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 0px;
    }
    .btn{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 8rem;
    
    }
    .btn button img{
        height: 10px;
        filter: invert(1);
    }

    .btn button:first-child{
        margin-left: 2rem;
    }
    .btn button:last-child{
        margin-right: 2rem;
    }

    .btn button{
        height: 30px;
        width: 30px;
        outline: none;
        border: none;
        background-color: #C45B4A;
        border-radius: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 10px 1px rgba(196,91,74,0.5);
        font-size: 1.5rem;
    
    }

    .backgroundImg {
        height: 40vh;
        width: 100%;
        margin-top: 0rem;
    top: 2rem;
      
      }
    
      .backgroundImg img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

}