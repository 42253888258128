.FeatureContentWrapper {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.FeatureContent {
  top: -13rem;
  width: 1240px;
  height: 262px;
  display: flex;
  position: absolute;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: #fffefd;
  box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
}

.upper {
  display: flex;
  padding: 0 3rem;
  align-items: center;
  justify-content: space-between;
 
}

.upper a {
  color: black;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
  margin-top: -1rem;
}

.upper a img {
  height: 8px;
  width: 15px;
}

.lower {
  display: flex;
  padding: 0 2rem;
  align-items: center;
  justify-content: space-around;
}

.right {
  display: flex;
  padding: 0 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid black;
}

.right h2 {
  margin: 0;
  font-family: "Lora";
  font-size: 1rem;
}
.right h5 {
  margin: 0;
  font-size: 2rem;
  color: #c1554e;
  font-weight: bold;
  font-family: "Lora";
  letter-spacing: 5px;
}

.events {
  width: 382px;
  height: 121px;
  display: flex;
  padding: 5rem 0;
  border-radius: 8px;
  align-items: center;
  background-color: #fffefd;
  justify-content: space-around;
  box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
  cursor: pointer;
}

.events:hover,
.content:hover {
  background-color: #fcb300;
  border-color: #fcb300;
}

.events h3 {
  font-size: 1rem;
  line-height: 30px;
  font-family: "Lora";
}

.left {
  padding: 0 1rem;
}

.lastEvent {
  height: 121px;
  width: 382px;
  border-radius: 8px;
  background-color: #fffefd;
  box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5rem 0;
  background-color: #fcb300;
}

.lastEvent .left {
  padding: 0 1rem;
}

.lastEvent h3 {
  font-size: 1rem;
  line-height: 30px;
  font-family: "Lora";
}
.lastEvent h5 {
  color: black;
}

.FeatureContentBody {
  /* height: 80vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 8rem 0 4rem 0;
}

.FeatureHead {
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.FeatureHead h1 img {
  height: 30px;
  width: 30px;
  margin: 0 15px;
}

.FeatureHead h1 {
  font-size: 2rem;
  font-weight: bold;
  color: black;
  font-family: "Lora";
}

.FeatureHead p {
  font-size: 1.8rem;
  color: rgba(0, 0, 0, 0.607);
  font-family: "Lora";
  letter-spacing: 0px;
}

.contentBody {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  /* grid-gap: 2rem; */
  grid-column-gap: 1rem;
  grid-row-gap: 3rem;
  margin-top: 2rem;
  padding: 0 8rem;
  box-sizing: border-box;
  /* padding-top: 4rem; */
}

.content {
  height: 82px;
  width: 280px;
  border: 2px solid black;
  border-radius: 5px;
  padding: 1rem 1rem;
  /* grid-gap: 0; */
}

.content h4 {
  font-size: 1rem;
  font-family: "Lora";
  font-weight: bold;
  margin-top: -0.2rem;
}

.content p {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.525);
}

.active {
  background-color: #fcb300;
  border: none;
}

.featureBg {
  height: 90vh;
  width: 100%;
}

.featureBg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* padding-top: -5rem !important; */
/* display: flex;
align-items: center;
justify-content: center; */
/* object-position:0px -195px; */
}



.SepcialContentBody{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin: 4rem 0; */
  margin-bottom: 6rem;
}

/* -----------------------------------------responsiveness-------------------------------------------------------------- */

@media screen and (max-width: 1300px) {
  .FeatureContentWrapper {
    width: 100%;
    /* height: 145vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .FeatureContent {
    width: 940px;
    height: 182px;
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.188);
    position: absolute;
    top: -10rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }

  .upper h1 {
    font-size: 1.5rem;
  }

  .events h3 {
    font-size: 0.7rem;
    line-height: 20px;
    font-family: "Lora";
  }
  .events {
    height: 75px;
    width: 352px;
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4rem 0;
    margin: 0 10px;
  }

  .lastEvent {
    height: 85px;
    width: 352px;
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4rem 0;
    background-color: #fcb300;
  }

  .lastEvent h3 {
    font-size: 0.7rem;
    line-height: 20px;
    font-family: "Lora";
  }

  .events h2{
    font-size: .7rem !important;
  }

.events h3{
  line-height: 0;
  font-size: .6rem !important;
}

  .content {
    height: 82px;
    width: 250px;
    border: 2px solid black;
    border-radius: 5px;
    padding: 1rem 1rem;
    /* grid-gap: 0; */
  }

  .active {
    background-color: #fcb300;
    border: none;
  }

  .contentBody {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    /* grid-gap: 2rem; */
    grid-column-gap: 1rem;
    grid-row-gap: 3rem;
    margin-top: 2rem;
    padding: 0 8rem;
    box-sizing: border-box;
  }

  .FeatureHead p {
    font-size: 1rem;
  }

  .content {
    height: 75px;
    width: 250px;
    border: 2px solid black;
    border-radius: 5px;
    padding: 1rem 1rem;
    /* grid-gap: 0; */
  }

  .content h4 {
    font-size: 0.9rem;
    font-family: "Lora";
    font-weight: bold;
    margin-top: -0.2rem;
  }

  .active {
    background-color: #fcb300;
    border: none;
  }
}
@media screen and (max-width: 1300px) {
  .FeatureContentWrapper {
    width: 100%;
    /* height: 135vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .FeatureContent {
    width: 840px;
    height: 182px;
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.188);
    position: absolute;
    top: -10rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }

  .upper h1 {
    font-size: 1.5rem;
  }

  .events h3 {
    font-size: 0.7rem;
    line-height: 20px;
    font-family: "Lora";
  }
  .events {
    /* height: auto;
    width: auto; */
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 0;
    margin: 0 10px;
  }

  .lastEvent {
    height: 85px;
    width: 332px;
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4rem 0;
    background-color: #fcb300;
  }

  .lastEvent h3 {
    font-size: 0.7rem;
    line-height: 20px;
    font-family: "Lora";
  }

  .content {
    height: 82px;
    width: 250px;
    border: 2px solid black;
    border-radius: 5px;
    padding: 1rem 1rem;
    /* grid-gap: 0; */
  }

  .active {
    background-color: #fcb300;
    border: none;
  }

  .contentBody {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    /* grid-gap: 2rem; */
    grid-column-gap: 1rem;
    grid-row-gap: 3rem;
    margin-top: 2rem;
    padding: 0 8rem;
    box-sizing: border-box;
  }

  .FeatureHead p {
    font-size: 1rem;
  }

  .content {
    height: 65px;
    width: 230px;
    border-radius: 5px;
    padding: 1rem 1rem;
    border: 2px solid black;
  }

  .content h4 {
    font-size: 0.8rem;
    font-weight: bold;
    font-family: "Lora";
    margin-top: -0.2rem;
  }

  .active {
    border: none;
    background-color: #fcb300;
  }
}
/* ---------------------------------540px------------------------------------- */






@media screen and (max-width: 540px) {
  .FeatureContentWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .FeatureContent {
    top: -1rem;
    width: 330px;
    height: 500px;
    display: flex;
    border-radius: 8px;
    position: relative;
    flex-direction: column;
    background-color: #fffefd;
    justify-content: space-around;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.242);
  }

  .upper {
    height: 5vh;
    width: 100%;
    display: flex;
    padding: 1rem 2rem;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }

  .upper a {
    color: black;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    text-decoration: underline;
  }

  .upper h1 {
    font-size: 1.3rem;
    font-family: "Lora";
  }

  .lower {
    display: flex;
    padding: 0 2rem;
    flex-direction: column;
  }

  .right {
    display: flex;
    padding: 0 1rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid black;
    /* overflow: hidden; */
  }

  .right h2 {
    margin: 0;
    font-family: "Lora";
    /* font-size: .5rem; */
  }
  .right h5 {
    margin: 0;
    color: red;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 5px;
    font-family: "Lora";
  }

  .events {
    width: 302px;
    height: 100px;
    display: flex;
    margin: 1rem 0;
    padding: 3rem 0;
    border-radius: 8px;
    align-items: center;
    background-color: #fffefd;
    justify-content: space-around;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
    flex-direction: column;
  }

  .events:hover {
    background-color: #fcb300;
  }

  .events h3 {
    font-size: 0.8rem;
    line-height: 30px;
  }

  .left {
    padding: 0 1rem;
  }
  

  .lastEvent {
    width: 302px;
    height: 100px;
    display: flex;
    padding: 3rem 0;
    border-radius: 8px;
    align-items: center;
    background-color: #fcb300;
    background-color: #fffefd;
    justify-content: space-around;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
  }

  .lastEvent .left {
    padding: 0 1rem;
  }

  .lastEvent h3 {
    font-size: 0.8rem;
    line-height: 25px;
  }
  .lastEvent h5 {
    color: black;
  }

  .FeatureContentBody {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
  }

  .FeatureHead {
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .FeatureHead h1 img {
    width: 15px;
    height: 15px;
  }

  .FeatureHead h1 {
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Lora";
  }

  .FeatureHead p {
    font-size: 1rem;
    font-family: "Lora";
    white-space: wrap;
    text-align: center;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.607);
  }

  .contentBody {
    display: grid;
    padding: 0rem;
    grid-gap: 1rem;
    justify-items: center;
    justify-items: center;
    align-content: center;
    box-sizing: border-box;
    grid-template-columns: 1fr;
  }

  .content {
    height: auto;
    width: 280px;
    border-radius: 5px;
    padding: 1rem 1rem;
    border: 2px solid black;
  }

  .content h4 {
    font-size: 1.1rem;
    font-family: "Lora";
    font-weight: bold;
    margin-top: -0.2rem;

  }

  .content p {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.525);
  }

  .active {
    background-color: #fcb300;
    border: none;
  }

  .featureBg {
    width: 100%;
    height: 40vh;
  }

  .featureBg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .lower .left h3{
  font-size: 1.2rem !important;
} */
}
