.download {
  /* height: 75vh; */
  width: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
}

.downloadWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.right {
  flex: 1;
  /* height: 60vh; */
  box-sizing: border-box;
  padding: 3rem;
}

.left {
  flex: 1;
  position: relative;
}

.AppHeading {
  font-size: 2.2rem !important;
  white-space: nowrap;
}

.lowerbg {

  padding: 3.5rem 0;
  width: 100%;
  background: linear-gradient(90deg, #c1564ec1 0%, #fcb500c0 100%);
}

.left img {
  height: 600px;
  width: 320px;
  position: absolute;
  top: -21rem;
  left: 4rem;
}

.AppdownLoadTitle img {
  height: 32px;
  width: 32px;
  margin-top: 10px;
}

.AppdownLoadTitle img:first-child {
  margin-right: 15px;
}

.AppdownLoadTitle img:last-child {
  margin-left: 15px;
}

.right h1 {
  font-size: 3rem;
  font-family: "Lora";
  position: relative;
  left: 0rem;
}

.AppdownLoadTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background-color: red; */
}



.right h2 img {
  height: 40px;
  width: 40px;
  margin-right: 20px;
}

.right h2 {
  font-size: 18px;
  font-family: "Lora";
  text-align: left;
  align-items: center;
  display: flex;
}

.right h1 span {
  color: #FCB300;
  /* text-decoration: underline; */
  position: relative;
}

.right h1 span::before {
  content: "";
  width: 80px;
  height: 3px;
  background-color: #FCB300;
  position: absolute;
  top: 4rem;
}

.social img {
  height: 50px;
  width: auto;
}

.social img:first-child {
  margin-right: 1rem;
}

.social {
  margin-top: 2rem;
}

.downloadWrappeMobilew {
  display: none;
}

/* ---------------------------1100px---------------------------------------- */

@media screen and (max-width: 1300px) {
  .download {
    /* height: 85vh; */
    width: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
  }

  .right {
    flex: 1;
    /* height: 70vh; */
    box-sizing: border-box;
    padding: 3rem;
  }

  .social img {
    height: 40px;
    width: auto;
  }


  .social img {
    height: 40px;
    width: auto;
  }

  .left img {
    height: 590px;
    width: 300px;
    position: absolute;
    top: -21rem;
    left: 4rem;
  }
}

/* ------------------------------------540px---------------------------------------------------------- */

@media screen and (max-width: 540px) {
  .download {
    /* height: 135vh; */
    width: 100%;
    display: flex;
    /* flex-direction: column-reverse; */
  }

  .left {
    width: 100%;
    height: 50%;
  }

  .right {
    width: 100%;
    height: 50%;
  }

  .AppHeading {
    font-size: 1.5rem !important;
    white-space: nowrap;
  }

  .downloadWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-top: 1rem;
    display: none;
  }

  .downloadWrappeMobilew {
    display: flex;
    flex-direction: column;
  }

  .downloadWrappeMobilew .left {
    display: flex;
    align-items: center;
  }

  .mobileTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4rem 0 2rem 0;

    /* padding: 0; */
  }

  .mobileTitle h1 {
    font-family: 'Lora';
    font-size: 1rem !important;
    white-space: nowrap;

  }

  .mobileTitle h1 img {
    height: 18px;
    margin: 0 .8rem;
  }

  .left img {
    height: 400px;
    width: 200px;
    position: relative;
    bottom: 0rem;
    top: 1rem;
    left: .5rem;
  }

  .right h1 img {
    height: 20px;
    width: 20px;
  }

  .right h1 {
    font-size: 1.5rem;
    font-family: "Lora";
  }

  .right h2 img {
    height: 20px;
    width: 20px;
    margin-right: 20px;
  }

  .right h2 {
    font-size: 12px;
    font-family: "Lora";
    text-align: left;
    align-items: flex-start;
    display: flex;
  }

  .mobileTitle h1 span {
    color: #FCB300;
    /* text-decoration: underline; */
    position: relative;
    margin-left: .6rem;
  }

  .mobileTitle h1 span::before {
    content: "";
    width: 40px;
    height: 3px;
    background-color: #FCB300;
    position: absolute;
    top: 2.2rem;
  }

  .social img {
    height: 40px;
    width: 120px;
    margin-left: 1rem;
  }

  .social img:first-child {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .social {
    margin-top: 2rem;
  }

  .lowerbg {
    display: none;
  }
}