.about {
  /* min-height: 180vh; */
  width: 100%;
  font-family: "Lora";
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.left {
  flex: 1;
  padding: 2rem 2rem 2rem 6rem;
}

.right {
  flex: 1;
  position: relative;
  left: 6rem;
}

.left .aboutHeading {
  position: relative;
}

.hidhiHeading{
  position: relative;

}

.hidhiHeading::after{
    content: "";
  background-color: #fcb300;
  color: #fcb300;
  /* text-decoration: underline; */
  height: 2px;
  width: 220px;
  position: absolute;
  top: 4rem;
  right: 12rem;
}

.left span {
  color: #fcb300;
  margin-left: .6rem;
}
.left .aboutHeading::after {
  content: "";
  background-color: #fcb300;
  color: #fcb300;
  /* text-decoration: underline; */
  height: 2px;
  width: 220px;
  position: absolute;
  top: 3.9rem;
  right: 2rem;
}
.leftTitleHead{
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftTitleHead img{
  height: 30px;
  margin: 0 10px;
  margin-top: 10px;
}
.leftTitle {
  display: flex;
  width: 100%;
  min-height: 15vh;
  align-items: center;
  justify-content: space-between;
}

.redRibbon {
  height: 40px;
  width: 100px;
}
.left h1 {
  font-size: 3rem;
  font-family: "Lora";
}

.left h1 img {
  height: 30px;
  width: 30px;
  /* color: yellow; */
  margin: 0 10px;
}

.left p {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
}

.InfocardWrapper {
  height: auto;
  width: 500px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.156),
    -5px -5px 15px rgba(255, 255, 255, 0.156);
  background-color: white;
  box-sizing: border-box;
  padding: 2rem;
  border-radius: 10px;
  position: absolute;
  bottom: -13rem;
}

.baby,
.event,
.audio,
.announcement {
  height: auto;
  /* margin: 10px 0; */
  padding: 10px 15px;
  margin: -8px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.InfocardWrapper h2 {
  margin: 0px 0 10px 0;
  font-size: 1rem;
  font-family: 'Lora' !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.InfocardWrapper h1 {
  font-family: "Lora";
}

.InfocardWrapper h2 span {
  color: black;
  top: 5px;
}

.InfocardWrapper span {
  color: #818181;
}

.announcement h2 span {
  /* left: 19rem;
  position: relative; */
}

.audio h2 span {
  left: 2rem;
  position: relative;
}

.event h2 span {
  left: 2rem;
  position: relative;
}

.baby h2 span {
  left: 9.5rem;
  position: relative;
}

.audio {
  background: linear-gradient(
    90deg,
    #fcb50055 0%,
    rgba(252, 181, 0, 0.186) 100%
  );
  color: #573900;
}

.audio h2 {
  color: #573900;
}

.InfocardWrapper hr {
  /* border: 1px solid #FCB300; */
  background-color: #fcb300;
  height: 1.5px;
  outline: none;
  border: none;
}

.backgroundImg {
  height: 80vh;
  width: 100%;
margin-top: 3rem;
}

.backgroundImg img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.aboutWrapper {
  /* height: 80vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.event {
  margin-top: 8px;
}

.announcement:hover {
  /* opacity: 0.36; */
  background: linear-gradient(90deg, #fcb50064 0%, rgba(252, 181, 0, 0.195) 100%);
  color: #573900;
}


@media screen and (max-width: 1400px) {
  .left h1 {
    font-size: 2.5rem;
    font-family: "Lora";
  }
}


/*----------------------------- responsiveness styles here ---------------------- */
@media screen and (max-width: 1300px) {
  .about {
    /* min-height: 180vh; */
    width: 100%;
    font-family: "Lora";
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }

  .left {
    flex: 1;
    padding: 4rem 3rem 2rem 7.5rem;
  }

  .left h1 {
    font-size: 2.2rem !important;
    font-family: "Lora";
   
  }

  .left p {
    font-size: 18px;
  }

  .left h1 img {
    height: 23px;
    width: 23px;
  }

  .left h1::after {
    content: "";
    background-color: #fcb300;
    color: #fcb300;
    /* text-decoration: underline; */
    height: 2px;
    width: 170px;
    position: absolute;
    top: 3rem;
    right: 2.2rem;
  }

  .right {
    flex: 1;
    padding: 0;
    position: relative;
    /* min-height: 70vh; */
    top: 0;
    left: 0;
    width: 100%;
  }

  .InfocardWrapper {
    height: auto;
    width: 400px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.156),
      -5px -5px 15px rgba(255, 255, 255, 0.156);
    background-color: white;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 10px;
    position: absolute;
    /* top: 0rem; */
    left: 1rem;
    bottom: -10rem;
  }

 
  .announcement {
    height: auto;
    /* margin: 10px 0; */
    padding: 10px 15px;
    margin: -8px 0;
    box-sizing: border-box;
    cursor: pointer;
  }

  .InfocardWrapper h2 {
    margin: 0px 0 10px 0;
    font-size: 1rem;
  }



  .audio h2 span {
    left: 1rem;
    position: relative;
  }

  .event h2 span {
    left: 0.8rem;
    position: relative;
  }

  .baby h2 span {
    left: 6.7rem;
    position: relative;
  }
}

/* ---------------------------------------------------1100px responsive-------------------------------------- */

@media screen and (max-width: 00px) {
  .about {
    /* height: 190vh; */
    width: 100%;
    font-family: "Lora";
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }

  .left {
    flex: 1;
    padding: 4rem 5rem 0 4.5rem;
    /* height: 90vh; */
  }

  .left h1 {
    font-size: 2rem;
    font-family: "Lora";
  }

  .left p {
    font-size: 1rem;
  }

  .left h1 img {
    height: 23px;
    width: 23px;
  }

  .left h1::after {
    content: "";
    background-color: #fcb300;
    color: #fcb300;
    /* text-decoration: underline; */
    height: 2px;
    width: 110px;
    position: absolute;
    top: 2.7rem;
    right: 2.4rem;
  }

  .right {
    flex: 1;
    padding: 0;
    position: relative;
    /* height: 90vh; */
    top: -3rem;
    left: 1rem;
  }

  .InfocardWrapper {
    height: 580px;
    width: 400px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.156),
      -5px -5px 15px rgba(255, 255, 255, 0.156);
    background-color: white;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 10px;
    /* margin-top: 10rem; */
    margin-top: 0rem;
  }

  .InfocardWrapper h2 {
    margin: 0px 0 15px 0 !important;
    font-size: 0.8rem;
  }


  .audio h2 span {
    left: 1rem;
    position: relative;
  }

  .event h2 span {
    left: 0.8rem;
    position: relative;
  }

  .baby h2 span {
    left: 6.7rem;
    position: relative;
  }
}

/* -------------------------------------------540------------------------------------------- */

@media screen and (max-width: 540px) {
  .about {
    /* height: 260vh; */
    width: 100%;
    font-family: "Lora";
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .aboutWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* height: 220vh; */
    width: 100%;
  }
  .left {
    display: flex;
    padding: 0 2rem;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .right {
    padding: 0rem;
    margin-top: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0rem;
    padding-bottom: 35rem;

  }

  .leftTitleHead img{
  height: 30px;
  margin: 0 10px;
  margin-top: 0px;
  /* display: none; */
}

  .redRibbon {
    height: 20px;
    width: 50px;
    display: none;
  }

  .left span {
    color: #fcb300;
    margin: 0;
  }
.left .aboutHeading::after {
  content: "";
  background-color: #fcb300;
  color: #fcb300;
  height: 2px;
  width: 220px;
  position: absolute;
  top: 3rem;
  right: 0rem;
 left: 0;
}

.hidhiHeading::after{
    content: "";
  background-color: #fcb300;
  color: #fcb300;
  height: 2px;
  width: 220px;
  position: absolute;
  top: 4rem;
  left: 0rem;
  right: 0;

} 
 
  .left h1 {
    font-size: 2rem  !important ;
    font-family: "Lora";
    white-space: nowrap;
  }

  .left h1 img {
    height: 20px;
    width: 20px;
    /* color: yellow; */
  }

  .leftTitle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
  min-height: auto;
  margin: 2rem 0;
  }

  .leftTitle h1{
    margin: 0;
    padding: 0;


  }

  .left p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 30px;
  }

  .InfocardWrapper {
    height: auto;
    width: 300px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.156),
      -5px -5px 15px rgba(255, 255, 255, 0.156);
    background-color: white;
    box-sizing: content-box;
    padding: 0rem;
    border-radius: 10px;
    top: 2rem;
    bottom: auto;
    padding: 2rem;

  }


  .announcement {
    height: auto;
    /* margin: 10px 0; */
    padding: 10px 15px;
    margin: -8px 0;
    box-sizing: border-box;
    cursor: pointer;
  }

  .InfocardWrapper h2 {
    margin: 0px 0 10px 0;
    font-size: 16px;
    font-family: 'Lora' !important;
    white-space: normal;
  }

  .InfocardWrapper h1 {
    font-family: "Lora";
    font-size: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .InfocardWrapper h2 span {
    color: black;
    top: 5px;
  }

  .InfocardWrapper span {
    color: rgba(0, 0, 0, 0.373);
  }




  .audio {
    background-color: #dffc0058;
  }

  .InfocardWrapper hr {
    background-color: #fcb300;
    height: 1.5px;
    outline: none;
    border: none;
  }

  .backgroundImg {
    height: 40vh;
    width: 100%;
    margin-top: 12rem;
  }

  .backgroundImg img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
