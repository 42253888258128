.PrayerSection {
  /* height: 170vh; */
  width: 100%;
}

.PrayerWrapper {
  width: 100%;
  /* height: 82vh; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -3rem;
}
.prayerHead {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 15vh;
}

.prayerHead {
  font-size: 2rem;
  font-family: "Lora";
  margin-top: 6rem;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
 
.btn button {
  background-color: #fcb300;
  height: 50px;
  width: 200px;
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  margin-bottom: 3rem;
  font-family: "Lora";
  color: #523B00;
}

.prayerBody {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 8rem;
}

.prayerBody p {
  font-size: 1.8rem;
}
.prayerBody span {
  /* font-size: 1.8rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #818181;
}

.prayerBody span img {
  width: 1.5rem;
  height: 1rem;
}

.prayerBodyRight {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prayerBodyRight p {
  font-family: "Roboto";
  font-weight: 400;
  line-height: 46px;
  font-size: 24px;
}

.prayerBodyMiddle {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
}

.prayerBodyMiddle span {
  margin: 0 10px;
  /* background-color: red; */
}

.prayerBodyLeft {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  /* padding: 0 rem; */
}

.prayerBodyLeft p {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 35px;
  text-align: center;
}

.prayerHead h1 {
  font-family: "Lora";
  font-size: 2rem;
  font-weight: bold;
}
.prayerHead span {
  font-family: "Lora";
  font-size: 18px;
  font-weight: bold;
}

.PrayerWrapper img {
  height: 28px;
  width: 28px;
  margin: 0 10px;
}

.prayerBg {
  width: 100%;
  height: 80vh;
}

.prayerBg img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}


/*--------------------------- responsiveness----------------------------------- */

@media screen and (max-width: 1300px) {
  .PrayerSection {
    /* height: 194vh; */
    width: 100%;
  }

  .PrayerWrapper {
    width: 100%;
    /* height: 110vh; */
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .prayerHead {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 15vh;
    margin-top: 6rem;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .prayerBody {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 4rem;
    margin: 2rem 0;
  }

  .prayerBody p {
    font-size: 1.8rem;
  }
  .prayerBody span {
    /* font-size: 1.8rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #818181;
  }

  .prayerBody span img {
    width: 1.5rem;
    height: 1rem;
  }

  .prayerBodyRight {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prayerBodyRight p {
    font-family: "Roboto";
    font-weight: 400;
    line-height: 46px;
    font-size: 20px;
  }

  .prayerBodyMiddle {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 2rem;
  }

  .prayerBodyMiddle span {
    margin: 0 10px;
    /* background-color: red; */
  }

  .prayerBodyLeft {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    /* padding: 0 rem; */
  }

  .prayerBodyLeft p {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 35px;
    text-align: center;
  }

  .prayerHead h1 {
    font-family: "Lora";
    font-size: 2rem;
    font-weight: bold;
  }
  .prayerHead span {
    font-family: "Lora";
    font-size: 1.2rem;
    font-weight: bold;
  }

  .PrayerWrapper img {
    height: 30px;
    width: 30px;
  }

  .prayerBg {
    width: 100%;
    height: 80vh;
 
  }

  .prayerBg img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

}

@media screen and (max-width: 540px) {
  .PrayerSection {
    width: 100%;
    margin-top: 0rem;
  }

  .PrayerWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
  }
  .prayerHead {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 15vh;
    margin-top: 1rem;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
  }

  .btn button {
    background-color: #fcb300;
    height: 40px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 10px;
    font-family: 'Lora';
  }

  .prayerBody {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 0 2rem;
  }

  .prayerBody p {
    font-size: 1rem;
  }
  .prayerBody span {
    /* font-size: 1.8rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #818181;
  }

  .prayerBody span img {
    width: 1.5rem;
    height: 1rem;
  }

  .prayerHead h1 {
    font-family: "Lora";
    font-size: 1.5rem;
    font-weight: bold;
  }
  .prayerHead span {
    font-family: "Lora";
    font-size: 1rem;
    font-weight: bold;
  }

  .PrayerWrapper img {
    height: 15px;
    width: 15px;
  }

  .prayerBg {
    width: 100%;
    height: 50vh;
 
  }

  .prayerBg img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
