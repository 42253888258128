.quizWrapper {
  /* height: 200vh; */
  width: 100%;
}

.quiz {
  width: 100%;
  display: flex;
  padding: 0 10rem;
  font-family: "Lora";
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.quizTitle {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.quiz img {
  height: 32px;
  width: 32px;
  margin: 0 10px;
}
.quiztitleHeading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quizTitle h1 span {
  font-size: 3.5rem;
  color: #ffaf00;
  text-align: center;
  position: relative;
}

.quizTitle h1 span::after {
  content: "";
  background-color: #ffaf00;
  color: #ffaf00;
  height: 3px;
  width: 110px;
  position: absolute;
  top: 4.7rem;
  right: 0rem;
}

.quizTitle h1 {
  font-size: 3.5rem;
  padding: 0rem 0;
  font-family: "Lora";
}
.quiz h2 {
  font-size: 2rem;
  text-align: center;
  font-family: "Lora";
}

.quizTitle p {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.467);
  font-weight: 500;
  margin-bottom: 5rem;
  margin-top: 1rem;
}

.quiz p {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.467);
  font-weight: 500;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.options input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;

  /* #FCB300 */
}

.options label {
  margin: 5px;
  /* height: 70px; */
  width: 44%;
  padding: 15px;
  font-size: 1.5rem;
  line-height: 24px;
  font-weight: bold;
  border-radius: 10px;
}

.selected_answer {
  color: white;
  background-color: #573900 !important;
  border: 2px solid #fcb300;
}

.wrong_answer {
  color: white;
  border: 2px solid #fcb300 !important;
  background-color: #c45c4e;
}

.correct_answer {
  color: white;
  background-color: #05aa90;
  border: 2px solid #fcb300 !important;
}

.unselected_answer {
  border: 2px solid black;
  background-color: #fff;
}
.unselected_answer:hover {
  background-color: #573900;
  color: white;
  border: 2px solid #fcb300;
  cursor: pointer !important;
}

.upper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px;
}

.lower {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px;
}

.btn {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-left: 0rem;
  align-items: center;
  margin-top: 3rem;
  position: relative;
  margin-bottom: 2rem;
}

.btn button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Lora" !important;
  outline: none;
  border: none;
  background-color: #fcb300;
  color: rgb(75, 6, 6);
  height: 56px;
  width: 229px;
  border-radius: 10px;
  margin-top: 3rem;
  margin-left: 0rem;
}

.btn button img {
  height: 20px;
  width: 20px;
  margin-left: 15px;
  margin-top: 0rem !important;
}

.anchor {
  text-decoration: underline;
  color: black;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  position: absolute;
  right: 0;
  bottom: 1.5rem;
  cursor: pointer;
}

.anchor img {
  height: 10px;
  width: 20px;
}

.quizbg {
  height: 80vh;
  width: 100%;
}

.quizbg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/*--------------------------- responsiveness----------------------------------- */

@media screen and (max-width: 1300px) {
  .quizWrapper {
    /* height: 210vh; */
    width: 100%;
  }
  .quizTitle {
    display: flex;
    margin-top: 2rem;
  }
  .quiz {
    width: 100%;
    /* height: 120vh; */
    font-family: "Lora";
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 10rem;
    box-sizing: border-box;
  }

  .quiz img {
    height: 32px;
    width: 32px;
    margin: 0 10px;
    margin-top: 20px !important;
  }

  .quizTitle h1 span {
    font-size: 2.3rem;
    color: #ffaf00;
    text-align: center;
    margin-bottom: 1rem;
    /* text-decoration: underline; */
    position: relative;
  }

  .quizTitle h1 span::after {
    content: "";
    background-color: #ffaf00;
    color: #ffaf00;
    /* text-decoration: underline; */
    height: 3px;
    width: 70px;
    position: absolute;
    top: 3.2rem;
    right: 0rem;
  }

  .quizTitle h1 {
    font-size: 2.5rem;
    /* color: #904b4b; */
    text-align: center;
    margin-bottom: 1.5rem;
    font-family: "Lora";
    padding-top: 2rem;
  }
  .quiz h2 {
    font-size: 1.5rem;
    /* color: #904b4b; */
    text-align: center;
    margin-bottom: 1rem;
    font-family: "Lora";
    padding-top: 1rem;
  }

  .quizTitle p {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.467);
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 3rem;
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  .options input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;

    /* #FCB300 */
  }

  .options label {
    width: 360px;
    height: 60px;
    /* padding: 20px 50px; */
    margin: 5px;
    padding-top: 15px;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer !important;
  }

  .selected_answer {
    color: white;
    background-color: #573900 !important;
    border: 2px solid #fcb300;
  }

  .wrong_answer {
    color: white;
    border: 2px solid #fcb300 !important;
    background-color: #c45c4e;
  }

  .correct_answer {
    color: white;
    background-color: #05aa90;
    border: 2px solid #fcb300 !important;
  }
  .unselected_answer {
    border: 2px solid black;
    cursor: pointer;
  }

  .btn {
    width: 100%;
    height: auto;
    display: flex;
    margin-left: 0rem;
    align-items: center;
    justify-content: center;
  }

  .btn .submitBtn img {
    height: 20px;
    width: 20px;
    margin-left: 15px;
    margin-top: 0rem !important;
  }

  .anchor img {
    height: 10px;
    width: 20px;
  }

  .quizbg {
    height: 80vh;
    width: 100%;
  }

  .quizbg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.quizScroll {
  width: 100%;
}

/* ------------------------------------------540px------------------------------------------------- */

@media screen and (max-width: 540px) {
  .quizWrapper {
    /* height: 140vh; */
    width: 100%;
  }

  .quiz {
    width: 100%;
    /* height: 110vh; */
    font-family: "Lora";
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
    padding-bottom: 4rem;
  }

  .quiz img {
    height: 18px;
    width: 18px;
  }
  .quiz img {
    margin: 0 10px;
    margin-top: 38px !important;
  }

  .quiz h1 span {
    font-size: 1.5rem;
    color: #ffaf00;
    text-align: center;
    margin-bottom: 1rem;
    /* text-decoration: underline; */
  }

  .quiz h1 span::after {
    content: "";
    background-color: #ffaf00;
    color: #ffaf00;
    /* text-decoration: underline; */
    height: 2px;
    width: 50px;
    position: absolute;
    top: 1.7rem;
    right: 0rem;
  }

  .quiz h1 {
    font-size: 1.5rem;
    /* color: #904b4b; */
    text-align: center;
    margin-bottom: 1rem;
    font-family: "Lora";
    padding-top: 2rem;
  }
  .quiz h2 {
    font-size: 1rem;
    /* color: #904b4b; */
    text-align: center;
    margin-bottom: 1rem;
    font-family: "Lora";
    padding-top: 2rem;
    /* white-space: nowrap; */
    margin-top: -1.4rem;
  }

  .quizTitle p {
    font-size: 1rem;
    /* letter-spacing: px; */
    color: rgba(0, 0, 0, 0.467);
    font-weight: 500;
    text-align: center;
  }

  .quiz p {
    font-size: 0.8rem;
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  .options input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;

    /* #FCB300 */
  }

  .options label {
    width: 90%;
    /* height: 50px; */
    /* padding: 20px 50px; */
    margin: 5px;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;
    font-family: "Lora";
    padding: 1rem;
    line-height: 20px;
    /* padding-top: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .selected_answer {
    color: white;
    background-color: #573900 !important;
    border: 2px solid #fcb300;
  }

  .wrong_answer {
    color: white;
    border: 2px solid #fcb300 !important;
    background-color: #c45c4e;
  }

  .correct_answer {
    color: white;
    background-color: #05aa90;
    border: 2px solid #fcb300 !important;
  }
  .unselected_answer {
    border: 2px solid black;
  }

  .upper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 5px;
  }

  .lower {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 5px;
  }

  .options button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: "Lora, serif";
    outline: none;
    border: none;
    background-color: #fcb300;
    height: 50px;
    width: 90% !important;
    border-radius: 10px;
    margin-top: 3rem;
    margin-left: 0rem;
    white-space: nowrap;
  }

  .btn {
    flex-direction: column;
  }

  .anchor {
    position: unset;
  }

  .anchor img {
    height: 7px;
    width: 15px;
  }

  .quizbg {
    height: 40vh;
    width: 100%;
  }

  .quizbg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
