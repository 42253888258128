@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");

/* background: linear-gradient(90deg, #C1554E 0%, #FCB300 100%); */

.Home_home__V_V4i {
  background: linear-gradient(90deg, #c1564e93 0%, #fcb500db 100%),
    url("/assests/images/bg.jpg");
  width: 100%;
  /* height: 110vh; */
  background-size: cover;
  /* background-position: center; */
  margin: 0;
  padding: 0 0 25rem 0;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  font-family: "Lora";
  /* position: absolute; */
}

.Home_homeHeader__ecXIW {
  width: 100%;
}

.Home_homeHeading__GhFoM {
  font-size: 2.7rem !important;
  font-family: "Lora";
  font-weight: 900 !important;
}

.Home_home__V_V4i .Home_inputfield__Q1Qqe {
  /* height: 76px; */
  height: 100%;
  width: 800px;
  /* opacity: 0.51; */
  border-radius: 8px !important;
  /* background-color: rgba(110, 78, 0, 0.87) !important; */
  padding: 0px 60px;
  border: none !important;
  outline: none !important;
  font-family: "Lora", serif;
  color: white !important;
}

/* .inputfield .child {
  opacity: 1;
} */

.Home_home__V_V4i input::-moz-placeholder {
  color: #f9f9f9 !important;
  font-family: Lora;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  opacity: 1 !important;
  filter: contrast(2);

}

.Home_home__V_V4i input:-ms-input-placeholder {
  color: #f9f9f9 !important;
  font-family: Lora;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  opacity: 1 !important;
  filter: contrast(2);

}

.Home_home__V_V4i input::placeholder {
  color: #f9f9f9 !important;
  font-family: Lora;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  opacity: 1 !important;
  filter: contrast(2);

}

.Home_subTitleMobileMobile__zv_gY {
  display: none;
}

.Home_home__V_V4i .Home_yellowBtn__0eNh2 {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1.5rem;
  color: #fcb301;
  position: relative;
  left: 5rem;
  /* top: 4.3rem; */
  z-index: 1;
  cursor: pointer;
}

.Home_yellowBtn__0eNh2 img {
  height: 20px;
  opacity: 1;

}

.Home_home__V_V4i h1 {
  font-size: 4rem;
  font-family: "Lora";
}

.Home_subTitleMobile__c0CIK {
  display: none;
}

.Home_headHeading__MNsiq img {
  height: 40px;
  margin: 0 10px;
  margin-top: 10px;
}

.Home_headHeading__MNsiq {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Home_head__IrBKJ h2 {
  font-family: "Lora";
  font-size: 2.1rem;
  /* margin-top: 2rem; */
  margin: 4rem 0;
}

.Home_head__IrBKJ h1 {
  color: #fcb300;
  -webkit-text-fill-color: #000000;
  -webkit-text-stroke: 1px;
}

/* .home span {
  color: #000000;
  -webkit-text-fill-color: #c1554e;
  -webkit-text-stroke: 1px;
} */

.Home_head__IrBKJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  top: 8rem;
  position: relative;

}

.Home_middleContent__gVhpT {

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 4rem; */

}

.Home_inputbox__OV_kS {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: auto;
}

.Home_searchInputWrapperContentHome__tRVwF {
  position: relative;
  width: 80% !important;
}

.Home_searchInputWrapperContentHome__tRVwF button {
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.348);
}

.Home_inputbox__OV_kS button {
  color: #FCB301 !important;
  font-size: 1.5rem;
}

/* -------------------------------------style for responsiveness ------------------------------------- */

@media screen and (max-width: 1300px) {
  .Home_head__IrBKJ h1 {
    font-size: 3.5rem;
  }

  .Home_head__IrBKJ h1 img {
    /* width: 55px; */
    height: 40px;
  }

  .Home_head__IrBKJ h2 {
    font-family: "Lora";
    font-size: 2rem;
    /* margin-top: 2rem; */
    margin: 3rem 0;
  }

  .Home_inputbox__OV_kS input {
    width: 600px !important;
    height: 45px !important;
  }

  .Home_head__IrBKJ {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 40%;
    width: 100%;
    /* background-color: blue; */
  }


  .Home_home__V_V4i {
    background-position: center;
    padding-bottom: 23rem;
  }
}


/* ---------------------------------540px---------------------------------------------- */


@media screen and (max-width: 540px) {
  .Home_home__V_V4i {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .Home_home__V_V4i {
    width: 100%;
    background-size: 170% 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    padding: 0 0 12rem 0;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: "Lora";
  }

  .Home_homeHeading__GhFoM {
    font-size: 2.3rem !important;
    white-space: normal;
    text-align: center;
  }

  .Home_homeHeading__GhFoM {
    color: #000000;
    -webkit-text-fill-color: #000000;
    -webkit-text-stroke: 0px;
  }

  .Home_headHeading__MNsiq {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }

  .Home_headHeading__MNsiq h1 {
    font-size: 2.5rem;
    text-align: center;

  }

  .Home_headHeading__MNsiq img {
    width: 35px;
    height: 10px;
    display: none;
  }

  .Home_head__IrBKJ h2 {
    font-size: 1rem !important;
    white-space: normal;
    text-align: center;
    display: none;

  }

  .Home_subTitleMobileMobile__zv_gY {
    display: flex !important;
  }

  .Home_subTitleMobile__c0CIK h2 {
    display: flex;
    font-size: 1rem !important;
    white-space: normal;
    text-align: center;
  }

  .Home_inputbox__OV_kS {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: auto;
  }

  .Home_inputbox__OV_kS input {
    width: 100% !important;
    height: 45px !important;
    margin-right: 2rem;
    padding-left: 3.5rem;

  }

  .Home_home__V_V4i .Home_yellowBtn__0eNh2 {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.3rem;
    color: #fcb301;
    position: relative;
    left: 4rem;
    top: 0.2rem;
    z-index: 1;
  }

  .Home_subTitle__QVpk0 {
    display: none;
  }

  .Home_subTitleMobile__c0CIK {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    padding: 0;


  }

  .Home_subTitleMobile__c0CIK h2 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

}

@media screen and (max-width: 350px) {


  .Home_inputbox__OV_kS input {
    width: 270px !important;
    height: 45px !important;
    margin-right: 2rem;
    padding-left: 3.5rem;
  }

  .Home_home__V_V4i .Home_yellowBtn__0eNh2 {
    border: none;
    outline: none;
    background-color: transparent;
    position: relative;
    left: 3rem;
    top: 0.2rem;
    z-index: 1;
  }

}
.Quiz_quizWrapper__tk8_i {
  /* height: 200vh; */
  width: 100%;
}

.Quiz_quiz__qJh9R {
  width: 100%;
  display: flex;
  padding: 0 10rem;
  font-family: "Lora";
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.Quiz_quizTitle__Dbryp {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.Quiz_quiz__qJh9R img {
  height: 32px;
  width: 32px;
  margin: 0 10px;
}
.Quiz_quiztitleHeading__FIqxw {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Quiz_quizTitle__Dbryp h1 span {
  font-size: 3.5rem;
  color: #ffaf00;
  text-align: center;
  position: relative;
}

.Quiz_quizTitle__Dbryp h1 span::after {
  content: "";
  background-color: #ffaf00;
  color: #ffaf00;
  height: 3px;
  width: 110px;
  position: absolute;
  top: 4.7rem;
  right: 0rem;
}

.Quiz_quizTitle__Dbryp h1 {
  font-size: 3.5rem;
  padding: 0rem 0;
  font-family: "Lora";
}
.Quiz_quiz__qJh9R h2 {
  font-size: 2rem;
  text-align: center;
  font-family: "Lora";
}

.Quiz_quizTitle__Dbryp p {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.467);
  font-weight: 500;
  margin-bottom: 5rem;
  margin-top: 1rem;
}

.Quiz_quiz__qJh9R p {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.467);
  font-weight: 500;
}

.Quiz_options__rRQot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.Quiz_options__rRQot input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;

  /* #FCB300 */
}

.Quiz_options__rRQot label {
  margin: 5px;
  /* height: 70px; */
  width: 44%;
  padding: 15px;
  font-size: 1.5rem;
  line-height: 24px;
  font-weight: bold;
  border-radius: 10px;
}

.Quiz_selected_answer__bzG4L {
  color: white;
  background-color: #573900 !important;
  border: 2px solid #fcb300;
}

.Quiz_wrong_answer__qykxU {
  color: white;
  border: 2px solid #fcb300 !important;
  background-color: #c45c4e;
}

.Quiz_correct_answer__ibnQ5 {
  color: white;
  background-color: #05aa90;
  border: 2px solid #fcb300 !important;
}

.Quiz_unselected_answer__X_PQ_ {
  border: 2px solid black;
  background-color: #fff;
}
.Quiz_unselected_answer__X_PQ_:hover {
  background-color: #573900;
  color: white;
  border: 2px solid #fcb300;
  cursor: pointer !important;
}

.Quiz_upper__1YJlJ {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px;
}

.Quiz_lower__2RwV6 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px;
}

.Quiz_btn__TNnnH {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-left: 0rem;
  align-items: center;
  margin-top: 3rem;
  position: relative;
  margin-bottom: 2rem;
}

.Quiz_btn__TNnnH button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Lora" !important;
  outline: none;
  border: none;
  background-color: #fcb300;
  color: rgb(75, 6, 6);
  height: 56px;
  width: 229px;
  border-radius: 10px;
  margin-top: 3rem;
  margin-left: 0rem;
}

.Quiz_btn__TNnnH button img {
  height: 20px;
  width: 20px;
  margin-left: 15px;
  margin-top: 0rem !important;
}

.Quiz_anchor__NQFQa {
  text-decoration: underline;
  color: black;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  position: absolute;
  right: 0;
  bottom: 1.5rem;
  cursor: pointer;
}

.Quiz_anchor__NQFQa img {
  height: 10px;
  width: 20px;
}

.Quiz_quizbg__Awc_a {
  height: 80vh;
  width: 100%;
}

.Quiz_quizbg__Awc_a img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/*--------------------------- responsiveness----------------------------------- */

@media screen and (max-width: 1300px) {
  .Quiz_quizWrapper__tk8_i {
    /* height: 210vh; */
    width: 100%;
  }
  .Quiz_quizTitle__Dbryp {
    display: flex;
    margin-top: 2rem;
  }
  .Quiz_quiz__qJh9R {
    width: 100%;
    /* height: 120vh; */
    font-family: "Lora";
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 10rem;
    box-sizing: border-box;
  }

  .Quiz_quiz__qJh9R img {
    height: 32px;
    width: 32px;
    margin: 0 10px;
    margin-top: 20px !important;
  }

  .Quiz_quizTitle__Dbryp h1 span {
    font-size: 2.3rem;
    color: #ffaf00;
    text-align: center;
    margin-bottom: 1rem;
    /* text-decoration: underline; */
    position: relative;
  }

  .Quiz_quizTitle__Dbryp h1 span::after {
    content: "";
    background-color: #ffaf00;
    color: #ffaf00;
    /* text-decoration: underline; */
    height: 3px;
    width: 70px;
    position: absolute;
    top: 3.2rem;
    right: 0rem;
  }

  .Quiz_quizTitle__Dbryp h1 {
    font-size: 2.5rem;
    /* color: #904b4b; */
    text-align: center;
    margin-bottom: 1.5rem;
    font-family: "Lora";
    padding-top: 2rem;
  }
  .Quiz_quiz__qJh9R h2 {
    font-size: 1.5rem;
    /* color: #904b4b; */
    text-align: center;
    margin-bottom: 1rem;
    font-family: "Lora";
    padding-top: 1rem;
  }

  .Quiz_quizTitle__Dbryp p {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.467);
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 3rem;
  }

  .Quiz_options__rRQot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  .Quiz_options__rRQot input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;

    /* #FCB300 */
  }

  .Quiz_options__rRQot label {
    width: 360px;
    height: 60px;
    /* padding: 20px 50px; */
    margin: 5px;
    padding-top: 15px;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer !important;
  }

  .Quiz_selected_answer__bzG4L {
    color: white;
    background-color: #573900 !important;
    border: 2px solid #fcb300;
  }

  .Quiz_wrong_answer__qykxU {
    color: white;
    border: 2px solid #fcb300 !important;
    background-color: #c45c4e;
  }

  .Quiz_correct_answer__ibnQ5 {
    color: white;
    background-color: #05aa90;
    border: 2px solid #fcb300 !important;
  }
  .Quiz_unselected_answer__X_PQ_ {
    border: 2px solid black;
    cursor: pointer;
  }

  .Quiz_btn__TNnnH {
    width: 100%;
    height: auto;
    display: flex;
    margin-left: 0rem;
    align-items: center;
    justify-content: center;
  }

  .Quiz_btn__TNnnH .Quiz_submitBtn__GtD8a img {
    height: 20px;
    width: 20px;
    margin-left: 15px;
    margin-top: 0rem !important;
  }

  .Quiz_anchor__NQFQa img {
    height: 10px;
    width: 20px;
  }

  .Quiz_quizbg__Awc_a {
    height: 80vh;
    width: 100%;
  }

  .Quiz_quizbg__Awc_a img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.Quiz_quizScroll__RbfFF {
  width: 100%;
}

/* ------------------------------------------540px------------------------------------------------- */

@media screen and (max-width: 540px) {
  .Quiz_quizWrapper__tk8_i {
    /* height: 140vh; */
    width: 100%;
  }

  .Quiz_quiz__qJh9R {
    width: 100%;
    /* height: 110vh; */
    font-family: "Lora";
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
    padding-bottom: 4rem;
  }

  .Quiz_quiz__qJh9R img {
    height: 18px;
    width: 18px;
  }
  .Quiz_quiz__qJh9R img {
    margin: 0 10px;
    margin-top: 38px !important;
  }

  .Quiz_quiz__qJh9R h1 span {
    font-size: 1.5rem;
    color: #ffaf00;
    text-align: center;
    margin-bottom: 1rem;
    /* text-decoration: underline; */
  }

  .Quiz_quiz__qJh9R h1 span::after {
    content: "";
    background-color: #ffaf00;
    color: #ffaf00;
    /* text-decoration: underline; */
    height: 2px;
    width: 50px;
    position: absolute;
    top: 1.7rem;
    right: 0rem;
  }

  .Quiz_quiz__qJh9R h1 {
    font-size: 1.5rem;
    /* color: #904b4b; */
    text-align: center;
    margin-bottom: 1rem;
    font-family: "Lora";
    padding-top: 2rem;
  }
  .Quiz_quiz__qJh9R h2 {
    font-size: 1rem;
    /* color: #904b4b; */
    text-align: center;
    margin-bottom: 1rem;
    font-family: "Lora";
    padding-top: 2rem;
    /* white-space: nowrap; */
    margin-top: -1.4rem;
  }

  .Quiz_quizTitle__Dbryp p {
    font-size: 1rem;
    /* letter-spacing: px; */
    color: rgba(0, 0, 0, 0.467);
    font-weight: 500;
    text-align: center;
  }

  .Quiz_quiz__qJh9R p {
    font-size: 0.8rem;
  }

  .Quiz_options__rRQot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  .Quiz_options__rRQot input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;

    /* #FCB300 */
  }

  .Quiz_options__rRQot label {
    width: 90%;
    /* height: 50px; */
    /* padding: 20px 50px; */
    margin: 5px;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;
    font-family: "Lora";
    padding: 1rem;
    line-height: 20px;
    /* padding-top: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Quiz_selected_answer__bzG4L {
    color: white;
    background-color: #573900 !important;
    border: 2px solid #fcb300;
  }

  .Quiz_wrong_answer__qykxU {
    color: white;
    border: 2px solid #fcb300 !important;
    background-color: #c45c4e;
  }

  .Quiz_correct_answer__ibnQ5 {
    color: white;
    background-color: #05aa90;
    border: 2px solid #fcb300 !important;
  }
  .Quiz_unselected_answer__X_PQ_ {
    border: 2px solid black;
  }

  .Quiz_upper__1YJlJ {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 5px;
  }

  .Quiz_lower__2RwV6 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 5px;
  }

  .Quiz_options__rRQot button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: "Lora, serif";
    outline: none;
    border: none;
    background-color: #fcb300;
    height: 50px;
    width: 90% !important;
    border-radius: 10px;
    margin-top: 3rem;
    margin-left: 0rem;
    white-space: nowrap;
  }

  .Quiz_btn__TNnnH {
    flex-direction: column;
  }

  .Quiz_anchor__NQFQa {
    position: unset;
  }

  .Quiz_anchor__NQFQa img {
    height: 7px;
    width: 15px;
  }

  .Quiz_quizbg__Awc_a {
    height: 40vh;
    width: 100%;
  }

  .Quiz_quizbg__Awc_a img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.About_about__rgaWE {
  /* min-height: 180vh; */
  width: 100%;
  font-family: "Lora";
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.About_left__bYdXV {
  flex: 1 1;
  padding: 2rem 2rem 2rem 6rem;
}

.About_right__IflAw {
  flex: 1 1;
  position: relative;
  left: 6rem;
}

.About_left__bYdXV .About_aboutHeading__29nSv {
  position: relative;
}

.About_hidhiHeading__MH86U{
  position: relative;

}

.About_hidhiHeading__MH86U::after{
    content: "";
  background-color: #fcb300;
  color: #fcb300;
  /* text-decoration: underline; */
  height: 2px;
  width: 220px;
  position: absolute;
  top: 4rem;
  right: 12rem;
}

.About_left__bYdXV span {
  color: #fcb300;
  margin-left: .6rem;
}
.About_left__bYdXV .About_aboutHeading__29nSv::after {
  content: "";
  background-color: #fcb300;
  color: #fcb300;
  /* text-decoration: underline; */
  height: 2px;
  width: 220px;
  position: absolute;
  top: 3.9rem;
  right: 2rem;
}
.About_leftTitleHead__4vEbl{
  display: flex;
  align-items: center;
  justify-content: center;
}
.About_leftTitleHead__4vEbl img{
  height: 30px;
  margin: 0 10px;
  margin-top: 10px;
}
.About_leftTitle__4MSP4 {
  display: flex;
  width: 100%;
  min-height: 15vh;
  align-items: center;
  justify-content: space-between;
}

.About_redRibbon__gcIoD {
  height: 40px;
  width: 100px;
}
.About_left__bYdXV h1 {
  font-size: 3rem;
  font-family: "Lora";
}

.About_left__bYdXV h1 img {
  height: 30px;
  width: 30px;
  /* color: yellow; */
  margin: 0 10px;
}

.About_left__bYdXV p {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
}

.About_InfocardWrapper__2nYGn {
  height: auto;
  width: 500px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.156),
    -5px -5px 15px rgba(255, 255, 255, 0.156);
  background-color: white;
  box-sizing: border-box;
  padding: 2rem;
  border-radius: 10px;
  position: absolute;
  bottom: -13rem;
}

.About_baby__OgK3J,
.About_event__CJneO,
.About_audio__5cXrZ,
.About_announcement__zmdQF {
  height: auto;
  /* margin: 10px 0; */
  padding: 10px 15px;
  margin: -8px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.About_InfocardWrapper__2nYGn h2 {
  margin: 0px 0 10px 0;
  font-size: 1rem;
  font-family: 'Lora' !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.About_InfocardWrapper__2nYGn h1 {
  font-family: "Lora";
}

.About_InfocardWrapper__2nYGn h2 span {
  color: black;
  top: 5px;
}

.About_InfocardWrapper__2nYGn span {
  color: #818181;
}

.About_announcement__zmdQF h2 span {
  /* left: 19rem;
  position: relative; */
}

.About_audio__5cXrZ h2 span {
  left: 2rem;
  position: relative;
}

.About_event__CJneO h2 span {
  left: 2rem;
  position: relative;
}

.About_baby__OgK3J h2 span {
  left: 9.5rem;
  position: relative;
}

.About_audio__5cXrZ {
  background: linear-gradient(
    90deg,
    #fcb50055 0%,
    rgba(252, 181, 0, 0.186) 100%
  );
  color: #573900;
}

.About_audio__5cXrZ h2 {
  color: #573900;
}

.About_InfocardWrapper__2nYGn hr {
  /* border: 1px solid #FCB300; */
  background-color: #fcb300;
  height: 1.5px;
  outline: none;
  border: none;
}

.About_backgroundImg__Wme8C {
  height: 80vh;
  width: 100%;
margin-top: 3rem;
}

.About_backgroundImg__Wme8C img{
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.About_aboutWrapper__8ppej {
  /* height: 80vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.About_event__CJneO {
  margin-top: 8px;
}

.About_announcement__zmdQF:hover {
  /* opacity: 0.36; */
  background: linear-gradient(90deg, #fcb50064 0%, rgba(252, 181, 0, 0.195) 100%);
  color: #573900;
}


@media screen and (max-width: 1400px) {
  .About_left__bYdXV h1 {
    font-size: 2.5rem;
    font-family: "Lora";
  }
}


/*----------------------------- responsiveness styles here ---------------------- */
@media screen and (max-width: 1300px) {
  .About_about__rgaWE {
    /* min-height: 180vh; */
    width: 100%;
    font-family: "Lora";
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }

  .About_left__bYdXV {
    flex: 1 1;
    padding: 4rem 3rem 2rem 7.5rem;
  }

  .About_left__bYdXV h1 {
    font-size: 2.2rem !important;
    font-family: "Lora";
   
  }

  .About_left__bYdXV p {
    font-size: 18px;
  }

  .About_left__bYdXV h1 img {
    height: 23px;
    width: 23px;
  }

  .About_left__bYdXV h1::after {
    content: "";
    background-color: #fcb300;
    color: #fcb300;
    /* text-decoration: underline; */
    height: 2px;
    width: 170px;
    position: absolute;
    top: 3rem;
    right: 2.2rem;
  }

  .About_right__IflAw {
    flex: 1 1;
    padding: 0;
    position: relative;
    /* min-height: 70vh; */
    top: 0;
    left: 0;
    width: 100%;
  }

  .About_InfocardWrapper__2nYGn {
    height: auto;
    width: 400px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.156),
      -5px -5px 15px rgba(255, 255, 255, 0.156);
    background-color: white;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 10px;
    position: absolute;
    /* top: 0rem; */
    left: 1rem;
    bottom: -10rem;
  }

 
  .About_announcement__zmdQF {
    height: auto;
    /* margin: 10px 0; */
    padding: 10px 15px;
    margin: -8px 0;
    box-sizing: border-box;
    cursor: pointer;
  }

  .About_InfocardWrapper__2nYGn h2 {
    margin: 0px 0 10px 0;
    font-size: 1rem;
  }



  .About_audio__5cXrZ h2 span {
    left: 1rem;
    position: relative;
  }

  .About_event__CJneO h2 span {
    left: 0.8rem;
    position: relative;
  }

  .About_baby__OgK3J h2 span {
    left: 6.7rem;
    position: relative;
  }
}

/* ---------------------------------------------------1100px responsive-------------------------------------- */

@media screen and (max-width: 00px) {
  .About_about__rgaWE {
    /* height: 190vh; */
    width: 100%;
    font-family: "Lora";
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }

  .About_left__bYdXV {
    flex: 1 1;
    padding: 4rem 5rem 0 4.5rem;
    /* height: 90vh; */
  }

  .About_left__bYdXV h1 {
    font-size: 2rem;
    font-family: "Lora";
  }

  .About_left__bYdXV p {
    font-size: 1rem;
  }

  .About_left__bYdXV h1 img {
    height: 23px;
    width: 23px;
  }

  .About_left__bYdXV h1::after {
    content: "";
    background-color: #fcb300;
    color: #fcb300;
    /* text-decoration: underline; */
    height: 2px;
    width: 110px;
    position: absolute;
    top: 2.7rem;
    right: 2.4rem;
  }

  .About_right__IflAw {
    flex: 1 1;
    padding: 0;
    position: relative;
    /* height: 90vh; */
    top: -3rem;
    left: 1rem;
  }

  .About_InfocardWrapper__2nYGn {
    height: 580px;
    width: 400px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.156),
      -5px -5px 15px rgba(255, 255, 255, 0.156);
    background-color: white;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 10px;
    /* margin-top: 10rem; */
    margin-top: 0rem;
  }

  .About_InfocardWrapper__2nYGn h2 {
    margin: 0px 0 15px 0 !important;
    font-size: 0.8rem;
  }


  .About_audio__5cXrZ h2 span {
    left: 1rem;
    position: relative;
  }

  .About_event__CJneO h2 span {
    left: 0.8rem;
    position: relative;
  }

  .About_baby__OgK3J h2 span {
    left: 6.7rem;
    position: relative;
  }
}

/* -------------------------------------------540------------------------------------------- */

@media screen and (max-width: 540px) {
  .About_about__rgaWE {
    /* height: 260vh; */
    width: 100%;
    font-family: "Lora";
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .About_aboutWrapper__8ppej {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* height: 220vh; */
    width: 100%;
  }
  .About_left__bYdXV {
    display: flex;
    padding: 0 2rem;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .About_right__IflAw {
    padding: 0rem;
    margin-top: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0rem;
    padding-bottom: 35rem;

  }

  .About_leftTitleHead__4vEbl img{
  height: 30px;
  margin: 0 10px;
  margin-top: 0px;
  /* display: none; */
}

  .About_redRibbon__gcIoD {
    height: 20px;
    width: 50px;
    display: none;
  }

  .About_left__bYdXV span {
    color: #fcb300;
    margin: 0;
  }
.About_left__bYdXV .About_aboutHeading__29nSv::after {
  content: "";
  background-color: #fcb300;
  color: #fcb300;
  height: 2px;
  width: 220px;
  position: absolute;
  top: 3rem;
  right: 0rem;
 left: 0;
}

.About_hidhiHeading__MH86U::after{
    content: "";
  background-color: #fcb300;
  color: #fcb300;
  height: 2px;
  width: 220px;
  position: absolute;
  top: 4rem;
  left: 0rem;
  right: 0;

} 
 
  .About_left__bYdXV h1 {
    font-size: 2rem  !important ;
    font-family: "Lora";
    white-space: nowrap;
  }

  .About_left__bYdXV h1 img {
    height: 20px;
    width: 20px;
    /* color: yellow; */
  }

  .About_leftTitle__4MSP4{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
  min-height: auto;
  margin: 2rem 0;
  }

  .About_leftTitle__4MSP4 h1{
    margin: 0;
    padding: 0;


  }

  .About_left__bYdXV p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 30px;
  }

  .About_InfocardWrapper__2nYGn {
    height: auto;
    width: 300px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.156),
      -5px -5px 15px rgba(255, 255, 255, 0.156);
    background-color: white;
    box-sizing: content-box;
    padding: 0rem;
    border-radius: 10px;
    top: 2rem;
    bottom: auto;
    padding: 2rem;

  }


  .About_announcement__zmdQF {
    height: auto;
    /* margin: 10px 0; */
    padding: 10px 15px;
    margin: -8px 0;
    box-sizing: border-box;
    cursor: pointer;
  }

  .About_InfocardWrapper__2nYGn h2 {
    margin: 0px 0 10px 0;
    font-size: 16px;
    font-family: 'Lora' !important;
    white-space: normal;
  }

  .About_InfocardWrapper__2nYGn h1 {
    font-family: "Lora";
    font-size: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .About_InfocardWrapper__2nYGn h2 span {
    color: black;
    top: 5px;
  }

  .About_InfocardWrapper__2nYGn span {
    color: rgba(0, 0, 0, 0.373);
  }




  .About_audio__5cXrZ {
    background-color: #dffc0058;
  }

  .About_InfocardWrapper__2nYGn hr {
    background-color: #fcb300;
    height: 1.5px;
    outline: none;
    border: none;
  }

  .About_backgroundImg__Wme8C {
    height: 40vh;
    width: 100%;
    margin-top: 12rem;
  }

  .About_backgroundImg__Wme8C img{
    height: 100%;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
}

.AppDownload_download__tcI3j {
  /* height: 75vh; */
  width: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
}

.AppDownload_downloadWrapper__ZlQfN {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.AppDownload_right__w6l0B {
  flex: 1 1;
  /* height: 60vh; */
  box-sizing: border-box;
  padding: 3rem;
}

.AppDownload_left__TI3WB {
  flex: 1 1;
  position: relative;
}

.AppDownload_AppHeading__3i1Vb {
  font-size: 2.2rem !important;
  white-space: nowrap;
}

.AppDownload_lowerbg__GQ0I2 {

  padding: 3.5rem 0;
  width: 100%;
  background: linear-gradient(90deg, #c1564ec1 0%, #fcb500c0 100%);
}

.AppDownload_left__TI3WB img {
  height: 600px;
  width: 320px;
  position: absolute;
  top: -21rem;
  left: 4rem;
}

.AppDownload_AppdownLoadTitle__t_scZ img {
  height: 32px;
  width: 32px;
  margin-top: 10px;
}

.AppDownload_AppdownLoadTitle__t_scZ img:first-child {
  margin-right: 15px;
}

.AppDownload_AppdownLoadTitle__t_scZ img:last-child {
  margin-left: 15px;
}

.AppDownload_right__w6l0B h1 {
  font-size: 3rem;
  font-family: "Lora";
  position: relative;
  left: 0rem;
}

.AppDownload_AppdownLoadTitle__t_scZ {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background-color: red; */
}



.AppDownload_right__w6l0B h2 img {
  height: 40px;
  width: 40px;
  margin-right: 20px;
}

.AppDownload_right__w6l0B h2 {
  font-size: 18px;
  font-family: "Lora";
  text-align: left;
  align-items: center;
  display: flex;
}

.AppDownload_right__w6l0B h1 span {
  color: #FCB300;
  /* text-decoration: underline; */
  position: relative;
}

.AppDownload_right__w6l0B h1 span::before {
  content: "";
  width: 80px;
  height: 3px;
  background-color: #FCB300;
  position: absolute;
  top: 4rem;
}

.AppDownload_social__J2dyK img {
  height: 50px;
  width: auto;
}

.AppDownload_social__J2dyK img:first-child {
  margin-right: 1rem;
}

.AppDownload_social__J2dyK {
  margin-top: 2rem;
}

.AppDownload_downloadWrappeMobilew__I_VFl {
  display: none;
}

/* ---------------------------1100px---------------------------------------- */

@media screen and (max-width: 1300px) {
  .AppDownload_download__tcI3j {
    /* height: 85vh; */
    width: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
  }

  .AppDownload_right__w6l0B {
    flex: 1 1;
    /* height: 70vh; */
    box-sizing: border-box;
    padding: 3rem;
  }

  .AppDownload_social__J2dyK img {
    height: 40px;
    width: auto;
  }


  .AppDownload_social__J2dyK img {
    height: 40px;
    width: auto;
  }

  .AppDownload_left__TI3WB img {
    height: 590px;
    width: 300px;
    position: absolute;
    top: -21rem;
    left: 4rem;
  }
}

/* ------------------------------------540px---------------------------------------------------------- */

@media screen and (max-width: 540px) {
  .AppDownload_download__tcI3j {
    /* height: 135vh; */
    width: 100%;
    display: flex;
    /* flex-direction: column-reverse; */
  }

  .AppDownload_left__TI3WB {
    width: 100%;
    height: 50%;
  }

  .AppDownload_right__w6l0B {
    width: 100%;
    height: 50%;
  }

  .AppDownload_AppHeading__3i1Vb {
    font-size: 1.5rem !important;
    white-space: nowrap;
  }

  .AppDownload_downloadWrapper__ZlQfN {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-top: 1rem;
    display: none;
  }

  .AppDownload_downloadWrappeMobilew__I_VFl {
    display: flex;
    flex-direction: column;
  }

  .AppDownload_downloadWrappeMobilew__I_VFl .AppDownload_left__TI3WB {
    display: flex;
    align-items: center;
  }

  .AppDownload_mobileTitle__b4OG1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4rem 0 2rem 0;

    /* padding: 0; */
  }

  .AppDownload_mobileTitle__b4OG1 h1 {
    font-family: 'Lora';
    font-size: 1rem !important;
    white-space: nowrap;

  }

  .AppDownload_mobileTitle__b4OG1 h1 img {
    height: 18px;
    margin: 0 .8rem;
  }

  .AppDownload_left__TI3WB img {
    height: 400px;
    width: 200px;
    position: relative;
    bottom: 0rem;
    top: 1rem;
    left: .5rem;
  }

  .AppDownload_right__w6l0B h1 img {
    height: 20px;
    width: 20px;
  }

  .AppDownload_right__w6l0B h1 {
    font-size: 1.5rem;
    font-family: "Lora";
  }

  .AppDownload_right__w6l0B h2 img {
    height: 20px;
    width: 20px;
    margin-right: 20px;
  }

  .AppDownload_right__w6l0B h2 {
    font-size: 12px;
    font-family: "Lora";
    text-align: left;
    align-items: flex-start;
    display: flex;
  }

  .AppDownload_mobileTitle__b4OG1 h1 span {
    color: #FCB300;
    /* text-decoration: underline; */
    position: relative;
    margin-left: .6rem;
  }

  .AppDownload_mobileTitle__b4OG1 h1 span::before {
    content: "";
    width: 40px;
    height: 3px;
    background-color: #FCB300;
    position: absolute;
    top: 2.2rem;
  }

  .AppDownload_social__J2dyK img {
    height: 40px;
    width: 120px;
    margin-left: 1rem;
  }

  .AppDownload_social__J2dyK img:first-child {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .AppDownload_social__J2dyK {
    margin-top: 2rem;
  }

  .AppDownload_lowerbg__GQ0I2 {
    display: none;
  }
}
.PrayerSection_PrayerSection__MWoqd {
  /* height: 170vh; */
  width: 100%;
}

.PrayerSection_PrayerWrapper__ZzIxV {
  width: 100%;
  /* height: 82vh; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -3rem;
}
.PrayerSection_prayerHead__vPKeJ {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 15vh;
}

.PrayerSection_prayerHead__vPKeJ {
  font-size: 2rem;
  font-family: "Lora";
  margin-top: 6rem;
}

.PrayerSection_btn__X4zbo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
 
.PrayerSection_btn__X4zbo button {
  background-color: #fcb300;
  height: 50px;
  width: 200px;
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  margin-bottom: 3rem;
  font-family: "Lora";
  color: #523B00;
}

.PrayerSection_prayerBody__x_azS {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 8rem;
}

.PrayerSection_prayerBody__x_azS p {
  font-size: 1.8rem;
}
.PrayerSection_prayerBody__x_azS span {
  /* font-size: 1.8rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #818181;
}

.PrayerSection_prayerBody__x_azS span img {
  width: 1.5rem;
  height: 1rem;
}

.PrayerSection_prayerBodyRight__7lQbE {
  flex: 3 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PrayerSection_prayerBodyRight__7lQbE p {
  font-family: "Roboto";
  font-weight: 400;
  line-height: 46px;
  font-size: 24px;
}

.PrayerSection_prayerBodyMiddle__XRnvz {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
}

.PrayerSection_prayerBodyMiddle__XRnvz span {
  margin: 0 10px;
  /* background-color: red; */
}

.PrayerSection_prayerBodyLeft__vt7m2 {
  flex: 3 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  /* padding: 0 rem; */
}

.PrayerSection_prayerBodyLeft__vt7m2 p {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 35px;
  text-align: center;
}

.PrayerSection_prayerHead__vPKeJ h1 {
  font-family: "Lora";
  font-size: 2rem;
  font-weight: bold;
}
.PrayerSection_prayerHead__vPKeJ span {
  font-family: "Lora";
  font-size: 18px;
  font-weight: bold;
}

.PrayerSection_PrayerWrapper__ZzIxV img {
  height: 28px;
  width: 28px;
  margin: 0 10px;
}

.PrayerSection_prayerBg__ek9mq {
  width: 100%;
  height: 80vh;
}

.PrayerSection_prayerBg__ek9mq img{
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


/*--------------------------- responsiveness----------------------------------- */

@media screen and (max-width: 1300px) {
  .PrayerSection_PrayerSection__MWoqd {
    /* height: 194vh; */
    width: 100%;
  }

  .PrayerSection_PrayerWrapper__ZzIxV {
    width: 100%;
    /* height: 110vh; */
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .PrayerSection_prayerHead__vPKeJ {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 15vh;
    margin-top: 6rem;
  }

  .PrayerSection_btn__X4zbo {
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .PrayerSection_prayerBody__x_azS {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 4rem;
    margin: 2rem 0;
  }

  .PrayerSection_prayerBody__x_azS p {
    font-size: 1.8rem;
  }
  .PrayerSection_prayerBody__x_azS span {
    /* font-size: 1.8rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #818181;
  }

  .PrayerSection_prayerBody__x_azS span img {
    width: 1.5rem;
    height: 1rem;
  }

  .PrayerSection_prayerBodyRight__7lQbE {
    flex: 3 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PrayerSection_prayerBodyRight__7lQbE p {
    font-family: "Roboto";
    font-weight: 400;
    line-height: 46px;
    font-size: 20px;
  }

  .PrayerSection_prayerBodyMiddle__XRnvz {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 2rem;
  }

  .PrayerSection_prayerBodyMiddle__XRnvz span {
    margin: 0 10px;
    /* background-color: red; */
  }

  .PrayerSection_prayerBodyLeft__vt7m2 {
    flex: 3 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    /* padding: 0 rem; */
  }

  .PrayerSection_prayerBodyLeft__vt7m2 p {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 35px;
    text-align: center;
  }

  .PrayerSection_prayerHead__vPKeJ h1 {
    font-family: "Lora";
    font-size: 2rem;
    font-weight: bold;
  }
  .PrayerSection_prayerHead__vPKeJ span {
    font-family: "Lora";
    font-size: 1.2rem;
    font-weight: bold;
  }

  .PrayerSection_PrayerWrapper__ZzIxV img {
    height: 30px;
    width: 30px;
  }

  .PrayerSection_prayerBg__ek9mq {
    width: 100%;
    height: 80vh;
 
  }

  .PrayerSection_prayerBg__ek9mq img{
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

}

@media screen and (max-width: 540px) {
  .PrayerSection_PrayerSection__MWoqd {
    width: 100%;
    margin-top: 0rem;
  }

  .PrayerSection_PrayerWrapper__ZzIxV {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
  }
  .PrayerSection_prayerHead__vPKeJ {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 15vh;
    margin-top: 1rem;
  }

  .PrayerSection_btn__X4zbo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
  }

  .PrayerSection_btn__X4zbo button {
    background-color: #fcb300;
    height: 40px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 10px;
    font-family: 'Lora';
  }

  .PrayerSection_prayerBody__x_azS {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 0 2rem;
  }

  .PrayerSection_prayerBody__x_azS p {
    font-size: 1rem;
  }
  .PrayerSection_prayerBody__x_azS span {
    /* font-size: 1.8rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #818181;
  }

  .PrayerSection_prayerBody__x_azS span img {
    width: 1.5rem;
    height: 1rem;
  }

  .PrayerSection_prayerHead__vPKeJ h1 {
    font-family: "Lora";
    font-size: 1.5rem;
    font-weight: bold;
  }
  .PrayerSection_prayerHead__vPKeJ span {
    font-family: "Lora";
    font-size: 1rem;
    font-weight: bold;
  }

  .PrayerSection_PrayerWrapper__ZzIxV img {
    height: 15px;
    width: 15px;
  }

  .PrayerSection_prayerBg__ek9mq {
    width: 100%;
    height: 50vh;
 
  }

  .PrayerSection_prayerBg__ek9mq img{
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.Facebook_facebook__EvIcn {
  width: 100%;
  padding: 0rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.Facebook_fbtitle__C34Lj {
  width: 100%;
  display: flex;
  margin: 3rem 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.Facebook_dashboard__ojGmI {
  margin-top: 1rem;
}

.Facebook_leftMainVid__B4jFY{
  width: 650px;
}

.Facebook_facebook__EvIcn h1 {
  font-size: 3rem;
  margin-top: 1rem;
  text-align: center;
  font-family: "Lora";
  color: rgb(0, 0, 0);
}

.Facebook_fbtitle__C34Lj h1 img {
  height: 30px;
  width: 30px;
  margin: 0 1.5rem;
}

.Facebook_facebook__EvIcn img {
  height: 30px;
  width: 30px;
}

.Facebook_facebook__EvIcn h1 span {
  color: #FFAF00;
  font-family: "Lora";
  position: relative;
}
.Facebook_facebook__EvIcn h1 span::after {
  content: "";
  height: 2px;
  top: 3.7rem;
  right: 1rem;
  width: 170px;
  color: #FFAF00;
  position: absolute;
  background-color: #fcb300;
}

.Facebook_facebook__EvIcn p {
  font-size: 1.5rem;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.585);
}



.Facebook_shivbg___XJ97 {
  height: 80vh;
  width: 100%;
 
}

.Facebook_shivbg___XJ97 img{
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


.Facebook_btn__WLSov {
  margin: 0;
  width: 100%;
  height: 15vh;
  display: flex;
  text-align: center;
  font-family: "Lora";
  align-items: center;
  font-family: "Lora";
  justify-content: center;
}
.Facebook_btn__WLSov h2 {
  font-size: 20px;
  font-family: "Lora";
}
.Facebook_btn__WLSov button {
  border: none;
  outline: none;
  display: flex;
  background: none;
  margin-left: 2rem;
  margin-top: -0.2rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Facebook_btn__WLSov button  {
  display: flex;
  padding: 8px 30px;
  font-size: 1.5rem;
  border-radius: 7px;
  font-weight: bold;
  text-align: center;
  font-family: "Lora";
  align-items: center;
  font-family: "Lora, serif";
  background-color: #FFAF00;
  color: rgba(0, 0, 0, 0.662);
  justify-content: space-evenly;
}
.Facebook_btn__WLSov button img {
  margin-right: 7px;
}

/*--------------- responsiveness styles here------------------------------- */

@media screen and (max-width: 1300px) {
  .Facebook_facebook__EvIcn {
    width: 100%;
    padding: 0rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
  }

  .Facebook_fbtitle__C34Lj {
    width: 100%;
    display: flex;
    margin: 2rem 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }



  /* .rightImg {
    width: 4.5vw;
    border: none;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 0.5vw;
  } */

  .Facebook_dashboard__ojGmI {
    margin-top: 0rem;
  }

  .Facebook_facebook__EvIcn h1 {
    margin-top: 1rem;
    font-size: 2.2rem;
    text-align: center;
    font-family: "Lora";
    color: rgb(0, 0, 0);
  }

  .Facebook_fbtitle__C34Lj h1 img {
    width: 30px;
    height: 30px;
    margin: 0 1.5rem;
  }

  .Facebook_facebook__EvIcn img {
    width: 30px;
    height: 30px;
  }

  .Facebook_facebook__EvIcn h1 span {
    color: #FFAF00;
    position: relative;
    font-family: "Lora";
  }
  .Facebook_facebook__EvIcn h1 span::after {
    height: 2px;
    top: 2.7rem;
    right: 0rem;
    content: "";
    width: 135px;
    color: #FFAF00;
    position: absolute;
    background-color: #fcb300;
  }

  .Facebook_facebook__EvIcn p {
    font-size: 1.5rem;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.585);
  }

  .Facebook_shivbg___XJ97 {
    height: 80vh;
    width: 100%;
   
  }
  
  .Facebook_shivbg___XJ97 img{
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .Facebook_btn__WLSov {
    width: 100%;
    height: 15vh;
    display: flex;
    margin: 1rem 0;
    align-items: center;
    padding-right: 9rem;
    justify-content: center;
    font-family: "Lora, serif";
  }
  .Facebook_btn__WLSov h2 {
    font-size: 20px;
    margin-right: 0rem;
    margin-left: -2rem;
    font-family: "Lora";
  }
  .Facebook_btn__WLSov button {
    border: none;
    outline: none;
    background: none;
    margin-left: 1rem;
  }

  .Facebook_btn__WLSov button  {
    display: flex;
    padding: 8px 30px;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    font-family: "Lora, serif";
    background-color: #fcb300;
    color: rgba(0, 0, 0, 0.662);
    justify-content: space-evenly;
  }
  .Facebook_btn__WLSov button img {
    margin-right: 7px;
  }
}

@media screen and (max-width: 540px) {
  .Facebook_facebook__EvIcn {
    width: 100%;
    padding: 0;
    display: flex;
    margin-top: 1rem;
    align-items: center;
    flex-direction: column;
  }

  .Facebook_dashboard__ojGmI {
    margin-top: 0rem;
  }

  .Facebook_facebook__EvIcn h1 {
    font-size: 1.5rem;
    margin-top: 1rem;
    text-align: center;
    color: rgb(0, 0, 0);
    font-family: "Lora";
  }   

  .Facebook_fbtitle__C34Lj {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .Facebook_fbtitle__C34Lj h1 img {
    width: 15px;
    height: 15px;
    margin: 0 0.8rem;
  }

  .Facebook_facebook__EvIcn img {
    width: 15px;
    height: 15px;
  }

  .Facebook_facebook__EvIcn h1 span {
    color: #FFAF00;
    text-decoration: underline;
  }


  .Facebook_facebook__EvIcn h1 span::after {
    width: 0;
    height: 0;
    content: "";
    color: transparent;
    background-color: transparent;
  }

  .Facebook_facebook__EvIcn p {
    font-size: 1rem;
    letter-spacing: 0px;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.585);
    text-align: center;
  }

  .Facebook_shivbg___XJ97 {
    height: 50vh;
    width: 100%;
   
  }
  
  .Facebook_shivbg___XJ97 img{
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .Facebook_btn__WLSov {
    width: 100%;
    height: 15vh;
    margin: 1rem 0;
    display: flex;
    padding-right: 0rem;
    font-family: "Lora";
    align-items: center;
    flex-direction: column;
    margin-top: -4rem;
  }
  .Facebook_btn__WLSov h2 {
    font-size: 12px;
    margin-left: 0rem;
    margin-right: 0rem;
    font-family: "Lora";
  }
  .Facebook_btn__WLSov button {
    border: none;
    outline: none;
    background: none;
    margin-left: 0rem;
  }

  .Facebook_btn__WLSov button  {
    display: flex;
    font-size: 1rem;
    padding: 8px 30px;
    font-weight: bold;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    font-family: "Lora";
    background-color: #fcb300;
    justify-content: space-evenly;
    color: rgba(0, 0, 0, 0.662);
  }
  .Facebook_btn__WLSov button img {
    margin-right: 7px;
  }
}

/* -------------------------------------------------faceboook dashboard-------------------------------------------------- */

.Facebook_FacebookDashboard__tF2tf {
  /* height: 80vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Facebook_left__UgEnR {
  flex: 4 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.Facebook_left__UgEnR img {
  width: 800px;
  height: 500px;
}

.Facebook_lefttitle__jmdCp {
  top: 1rem;
  left: 3rem;
  position: absolute;
}

.Facebook_lefttitle__jmdCp h6 {
  color: white;
  font-size: 1rem;
}

.Facebook_lefttitle__jmdCp img {
  top: 20rem;
  left: 27rem;
  width: 50px;
  height: 50px;
  position: absolute;
}

.Facebook_right__y8jiu {
  flex: 3 1;
  display: flex;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Facebook_rightWrapper__o2Htk {
  width: 100%;
  display: flex;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
}

.Facebook_rightImg__Mu3mu {
  width: 8.5vw;
  border: none;
  aspect-ratio: 16/9;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.5vw;
}

.Facebook_rightInfo__ZJT_X {
  flex: 2 1;
  height: 100%;
  width: 12vw;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Facebook_rightInfo__ZJT_X h5 {
  font-size: 0.9rem;
}

.Facebook_rightInfo__ZJT_X h4 {
  color: rgba(0, 0, 0, 0.553);
  font-size: 1rem;
  letter-spacing: normal;
  font-family: "Lora, serif";
  margin-top: 0;
}

.Facebook_rightWrapper__o2Htk img {
  border-radius: 5px;
}
.Facebook_rightImg__Mu3mu {
  margin-right: 1rem;
}

.Facebook_rightImg__Mu3mu img {
  width: 230px;
  height: 102px;
}

/*-------------------- responsiveness----------------------------- */

@media screen and (max-width: 1300px) {
  .Facebook_FacebookDashboard__tF2tf {
    /* height: 70vh; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
 
    margin-top: -6rem !important;
    /* padding: 0; */
    margin-bottom: 2rem !important;
  }

  .Facebook_left__UgEnR {
    flex: 4 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* height: 100%; */
    width: 100%;

  }

  .Facebook_left__UgEnR img {
    height: 400px;
    width: 600px;
  }

  .Facebook_lefttitle__jmdCp {
    position: absolute;

    top: 0rem;
    left: 2rem;
  }

  .Facebook_lefttitle__jmdCp h6 {
    color: white;
    font-size: 1rem;
  }

  .Facebook_lefttitle__jmdCp img {
    top: 14rem;
    left: 18rem;
    position: absolute;
    height: 50px;
    width: 50px;
  }

  .Facebook_leftMainVid__B4jFY{
    width: 57vw;
    /* margin-top: 7rem; */
    position: relative;
    top: 4rem;
  }

  .Facebook_right__y8jiu {
    flex: 3 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-left: 1rem;

    margin-top: 8rem !important;
  }

  .Facebook_rightImg__Mu3mu {
   width: 35.5%;
   margin: 0rem 0;
  }



  .Facebook_rightInfo__ZJT_X {
    height: 100%;
    flex: 2 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1rem;
  }

  .Facebook_rightInfo__ZJT_X h5 {
    font-size: 0.9rem;
  }

  .Facebook_rightInfo__ZJT_X h4 {
    color: rgba(0, 0, 0, 0.553);
    font-size: 1rem;
    letter-spacing: normal;
    font-family: "Lora, serif";
    margin-top: 1rem;
  }

  .Facebook_rightWrapper__o2Htk img {
    border-radius: 5px;
  }
  .Facebook_rightImg__Mu3mu {
    margin-right: 1rem;
  }

  .Facebook_rightImg__Mu3mu img {
    width: 160px;
    height: 85px;
  }
  .Facebook_org__gvT75 {
    margin: 2px 0 2px 0;
  }

  .Facebook_rightWrapper__o2Htk h5 {
    font-size: 0.9rem;
    margin: 0;
  }


  .Facebook_rightWrapper__o2Htk {
    display: flex;
    margin: 10px 0;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
  }

}

/* ---------------------------------------540px--------------------------------------------- */

@media screen and (max-width: 540px) {
  .Facebook_FacebookDashboard__tF2tf {
    /* height: 100vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .Facebook_left__UgEnR {
    flex: 4 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .Facebook_left__UgEnR img {
    width: 350px;
    height: 200px;
  }

  .Facebook_lefttitle__jmdCp {
    position: absolute;

    top: -1rem;
    left: 1rem;
  }

  .Facebook_lefttitle__jmdCp h6 {
    color: white;
    font-size: 0.6rem;
  }

  .Facebook_leftMainVid__B4jFY{
    width: 350px;
    /* margin-top: 7rem; */
    position: relative;
    top: 4rem;
    margin-top: 2rem;
  }

  .Facebook_lefttitle__jmdCp img {
    /* top: 4rem; */
    top: 8rem;
    left: 10rem;
    /* position: relative; */
    position: absolute;
    height: 30px;
    width: 30px;
  }

  .Facebook_right__y8jiu {
    flex: 3 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5rem;
    margin-left: 0;
    padding: 0 0.7rem;
    box-sizing: border-box;
    position: relative;
    top: -4rem;
  }

  .Facebook_rightWrapper__o2Htk {
    display: flex;
    margin: 5px 0;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
  }

  .Facebook_org__gvT75 {
    margin: 2px 0 2px 0;
  }

  .Facebook_rightImg__Mu3mu {
    flex: 1.5 1;
    height: 100%;
  }

  .Facebook_rightInfo__ZJT_X{
    margin-top: 2rem;
  }

  .Facebook_rightInfo__ZJT_X {
    height: 100%;
    flex: 2 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: -1rem;
  }

  .Facebook_rightInfo__ZJT_X h4 {
    color: rgba(0, 0, 0, 0.553);
    font-size: 0.7rem;
    letter-spacing: normal;
    font-family: "Lora, serif";
    white-space: nowrap;
  }
  .Facebook_rightWrapper__o2Htk h5 {
    font-size: 0.7rem;
    /* white-space: nowrap; */
    margin: 0;
  }
  .Facebook_rightWrapper__o2Htk img {
    border-radius: 5px;
  }
  .Facebook_rightImg__Mu3mu {
    margin-right: 1rem;
  }
  .Facebook_rightImg__Mu3mu img {
    height: 70px;
    width: 130px;
  }
}

.FeatureContent_FeatureContentWrapper__mV_De {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.FeatureContent_FeatureContent__EKIMF {
  top: -13rem;
  width: 1240px;
  height: 262px;
  display: flex;
  position: absolute;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: #fffefd;
  box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
}

.FeatureContent_upper__kJTF_ {
  display: flex;
  padding: 0 3rem;
  align-items: center;
  justify-content: space-between;
 
}

.FeatureContent_upper__kJTF_ a {
  color: black;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
  margin-top: -1rem;
}

.FeatureContent_upper__kJTF_ a img {
  height: 8px;
  width: 15px;
}

.FeatureContent_lower__lu27t {
  display: flex;
  padding: 0 2rem;
  align-items: center;
  justify-content: space-around;
}

.FeatureContent_right__T7EEq {
  display: flex;
  padding: 0 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid black;
}

.FeatureContent_right__T7EEq h2 {
  margin: 0;
  font-family: "Lora";
  font-size: 1rem;
}
.FeatureContent_right__T7EEq h5 {
  margin: 0;
  font-size: 2rem;
  color: #c1554e;
  font-weight: bold;
  font-family: "Lora";
  letter-spacing: 5px;
}

.FeatureContent_events__Ht7kg {
  width: 382px;
  height: 121px;
  display: flex;
  padding: 5rem 0;
  border-radius: 8px;
  align-items: center;
  background-color: #fffefd;
  justify-content: space-around;
  box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
  cursor: pointer;
}

.FeatureContent_events__Ht7kg:hover,
.FeatureContent_content__kRatX:hover {
  background-color: #fcb300;
  border-color: #fcb300;
}

.FeatureContent_events__Ht7kg h3 {
  font-size: 1rem;
  line-height: 30px;
  font-family: "Lora";
}

.FeatureContent_left__0nwDz {
  padding: 0 1rem;
}

.FeatureContent_lastEvent__9IEgk {
  height: 121px;
  width: 382px;
  border-radius: 8px;
  background-color: #fffefd;
  box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5rem 0;
  background-color: #fcb300;
}

.FeatureContent_lastEvent__9IEgk .FeatureContent_left__0nwDz {
  padding: 0 1rem;
}

.FeatureContent_lastEvent__9IEgk h3 {
  font-size: 1rem;
  line-height: 30px;
  font-family: "Lora";
}
.FeatureContent_lastEvent__9IEgk h5 {
  color: black;
}

.FeatureContent_FeatureContentBody__HfxO8 {
  /* height: 80vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 8rem 0 4rem 0;
}

.FeatureContent_FeatureHead__G_9Qj {
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.FeatureContent_FeatureHead__G_9Qj h1 img {
  height: 30px;
  width: 30px;
  margin: 0 15px;
}

.FeatureContent_FeatureHead__G_9Qj h1 {
  font-size: 2rem;
  font-weight: bold;
  color: black;
  font-family: "Lora";
}

.FeatureContent_FeatureHead__G_9Qj p {
  font-size: 1.8rem;
  color: rgba(0, 0, 0, 0.607);
  font-family: "Lora";
  letter-spacing: 0px;
}

.FeatureContent_contentBody__sb4ta {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  /* grid-gap: 2rem; */
  grid-column-gap: 1rem;
  grid-row-gap: 3rem;
  margin-top: 2rem;
  padding: 0 8rem;
  box-sizing: border-box;
  /* padding-top: 4rem; */
}

.FeatureContent_content__kRatX {
  height: 82px;
  width: 280px;
  border: 2px solid black;
  border-radius: 5px;
  padding: 1rem 1rem;
  /* grid-gap: 0; */
}

.FeatureContent_content__kRatX h4 {
  font-size: 1rem;
  font-family: "Lora";
  font-weight: bold;
  margin-top: -0.2rem;
}

.FeatureContent_content__kRatX p {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.525);
}

.FeatureContent_active__Gfjyb {
  background-color: #fcb300;
  border: none;
}

.FeatureContent_featureBg____C_r {
  height: 90vh;
  width: 100%;
}

.FeatureContent_featureBg____C_r img{
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  /* padding-top: -5rem !important; */
/* display: flex;
align-items: center;
justify-content: center; */
/* object-position:0px -195px; */
}



.FeatureContent_SepcialContentBody__D6Cu7{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin: 4rem 0; */
  margin-bottom: 6rem;
}

/* -----------------------------------------responsiveness-------------------------------------------------------------- */

@media screen and (max-width: 1300px) {
  .FeatureContent_FeatureContentWrapper__mV_De {
    width: 100%;
    /* height: 145vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .FeatureContent_FeatureContent__EKIMF {
    width: 940px;
    height: 182px;
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.188);
    position: absolute;
    top: -10rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }

  .FeatureContent_upper__kJTF_ h1 {
    font-size: 1.5rem;
  }

  .FeatureContent_events__Ht7kg h3 {
    font-size: 0.7rem;
    line-height: 20px;
    font-family: "Lora";
  }
  .FeatureContent_events__Ht7kg {
    height: 75px;
    width: 352px;
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4rem 0;
    margin: 0 10px;
  }

  .FeatureContent_lastEvent__9IEgk {
    height: 85px;
    width: 352px;
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4rem 0;
    background-color: #fcb300;
  }

  .FeatureContent_lastEvent__9IEgk h3 {
    font-size: 0.7rem;
    line-height: 20px;
    font-family: "Lora";
  }

  .FeatureContent_events__Ht7kg h2{
    font-size: .7rem !important;
  }

.FeatureContent_events__Ht7kg h3{
  line-height: 0;
  font-size: .6rem !important;
}

  .FeatureContent_content__kRatX {
    height: 82px;
    width: 250px;
    border: 2px solid black;
    border-radius: 5px;
    padding: 1rem 1rem;
    /* grid-gap: 0; */
  }

  .FeatureContent_active__Gfjyb {
    background-color: #fcb300;
    border: none;
  }

  .FeatureContent_contentBody__sb4ta {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    /* grid-gap: 2rem; */
    grid-column-gap: 1rem;
    grid-row-gap: 3rem;
    margin-top: 2rem;
    padding: 0 8rem;
    box-sizing: border-box;
  }

  .FeatureContent_FeatureHead__G_9Qj p {
    font-size: 1rem;
  }

  .FeatureContent_content__kRatX {
    height: 75px;
    width: 250px;
    border: 2px solid black;
    border-radius: 5px;
    padding: 1rem 1rem;
    /* grid-gap: 0; */
  }

  .FeatureContent_content__kRatX h4 {
    font-size: 0.9rem;
    font-family: "Lora";
    font-weight: bold;
    margin-top: -0.2rem;
  }

  .FeatureContent_active__Gfjyb {
    background-color: #fcb300;
    border: none;
  }
}
@media screen and (max-width: 1300px) {
  .FeatureContent_FeatureContentWrapper__mV_De {
    width: 100%;
    /* height: 135vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .FeatureContent_FeatureContent__EKIMF {
    width: 840px;
    height: 182px;
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.188);
    position: absolute;
    top: -10rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }

  .FeatureContent_upper__kJTF_ h1 {
    font-size: 1.5rem;
  }

  .FeatureContent_events__Ht7kg h3 {
    font-size: 0.7rem;
    line-height: 20px;
    font-family: "Lora";
  }
  .FeatureContent_events__Ht7kg {
    /* height: auto;
    width: auto; */
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 0;
    margin: 0 10px;
  }

  .FeatureContent_lastEvent__9IEgk {
    height: 85px;
    width: 332px;
    border-radius: 8px;
    background-color: #fffefd;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4rem 0;
    background-color: #fcb300;
  }

  .FeatureContent_lastEvent__9IEgk h3 {
    font-size: 0.7rem;
    line-height: 20px;
    font-family: "Lora";
  }

  .FeatureContent_content__kRatX {
    height: 82px;
    width: 250px;
    border: 2px solid black;
    border-radius: 5px;
    padding: 1rem 1rem;
    /* grid-gap: 0; */
  }

  .FeatureContent_active__Gfjyb {
    background-color: #fcb300;
    border: none;
  }

  .FeatureContent_contentBody__sb4ta {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    /* grid-gap: 2rem; */
    grid-column-gap: 1rem;
    grid-row-gap: 3rem;
    margin-top: 2rem;
    padding: 0 8rem;
    box-sizing: border-box;
  }

  .FeatureContent_FeatureHead__G_9Qj p {
    font-size: 1rem;
  }

  .FeatureContent_content__kRatX {
    height: 65px;
    width: 230px;
    border-radius: 5px;
    padding: 1rem 1rem;
    border: 2px solid black;
  }

  .FeatureContent_content__kRatX h4 {
    font-size: 0.8rem;
    font-weight: bold;
    font-family: "Lora";
    margin-top: -0.2rem;
  }

  .FeatureContent_active__Gfjyb {
    border: none;
    background-color: #fcb300;
  }
}
/* ---------------------------------540px------------------------------------- */






@media screen and (max-width: 540px) {
  .FeatureContent_FeatureContentWrapper__mV_De {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .FeatureContent_FeatureContent__EKIMF {
    top: -1rem;
    width: 330px;
    height: 500px;
    display: flex;
    border-radius: 8px;
    position: relative;
    flex-direction: column;
    background-color: #fffefd;
    justify-content: space-around;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.242);
  }

  .FeatureContent_upper__kJTF_ {
    height: 5vh;
    width: 100%;
    display: flex;
    padding: 1rem 2rem;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }

  .FeatureContent_upper__kJTF_ a {
    color: black;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    text-decoration: underline;
  }

  .FeatureContent_upper__kJTF_ h1 {
    font-size: 1.3rem;
    font-family: "Lora";
  }

  .FeatureContent_lower__lu27t {
    display: flex;
    padding: 0 2rem;
    flex-direction: column;
  }

  .FeatureContent_right__T7EEq {
    display: flex;
    padding: 0 1rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid black;
    /* overflow: hidden; */
  }

  .FeatureContent_right__T7EEq h2 {
    margin: 0;
    font-family: "Lora";
    /* font-size: .5rem; */
  }
  .FeatureContent_right__T7EEq h5 {
    margin: 0;
    color: red;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 5px;
    font-family: "Lora";
  }

  .FeatureContent_events__Ht7kg {
    width: 302px;
    height: 100px;
    display: flex;
    margin: 1rem 0;
    padding: 3rem 0;
    border-radius: 8px;
    align-items: center;
    background-color: #fffefd;
    justify-content: space-around;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
    flex-direction: column;
  }

  .FeatureContent_events__Ht7kg:hover {
    background-color: #fcb300;
  }

  .FeatureContent_events__Ht7kg h3 {
    font-size: 0.8rem;
    line-height: 30px;
  }

  .FeatureContent_left__0nwDz {
    padding: 0 1rem;
  }
  

  .FeatureContent_lastEvent__9IEgk {
    width: 302px;
    height: 100px;
    display: flex;
    padding: 3rem 0;
    border-radius: 8px;
    align-items: center;
    background-color: #fcb300;
    background-color: #fffefd;
    justify-content: space-around;
    box-shadow: 0 8px 64px 0 rgba(19, 23, 61, 0.08);
  }

  .FeatureContent_lastEvent__9IEgk .FeatureContent_left__0nwDz {
    padding: 0 1rem;
  }

  .FeatureContent_lastEvent__9IEgk h3 {
    font-size: 0.8rem;
    line-height: 25px;
  }
  .FeatureContent_lastEvent__9IEgk h5 {
    color: black;
  }

  .FeatureContent_FeatureContentBody__HfxO8 {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
  }

  .FeatureContent_FeatureHead__G_9Qj {
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .FeatureContent_FeatureHead__G_9Qj h1 img {
    width: 15px;
    height: 15px;
  }

  .FeatureContent_FeatureHead__G_9Qj h1 {
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Lora";
  }

  .FeatureContent_FeatureHead__G_9Qj p {
    font-size: 1rem;
    font-family: "Lora";
    white-space: wrap;
    text-align: center;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.607);
  }

  .FeatureContent_contentBody__sb4ta {
    display: grid;
    padding: 0rem;
    grid-gap: 1rem;
    justify-items: center;
    justify-items: center;
    align-content: center;
    box-sizing: border-box;
    grid-template-columns: 1fr;
  }

  .FeatureContent_content__kRatX {
    height: auto;
    width: 280px;
    border-radius: 5px;
    padding: 1rem 1rem;
    border: 2px solid black;
  }

  .FeatureContent_content__kRatX h4 {
    font-size: 1.1rem;
    font-family: "Lora";
    font-weight: bold;
    margin-top: -0.2rem;

  }

  .FeatureContent_content__kRatX p {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.525);
  }

  .FeatureContent_active__Gfjyb {
    background-color: #fcb300;
    border: none;
  }

  .FeatureContent_featureBg____C_r {
    width: 100%;
    height: 40vh;
  }

  .FeatureContent_featureBg____C_r img{
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/* .lower .left h3{
  font-size: 1.2rem !important;
} */
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/_next/static/media/slick.25572f22.eot);
    src: url(/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/_next/static/media/slick.f895cfdf.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

.Gallery_wrapper__LGJad{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction:column;
    width:100%;
    padding-bottom: 7rem !important;   
}

.Gallery_title__f1tfQ{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    margin-top: 4rem;
}

.Gallery_title__f1tfQ h1{
    font-family: 'Lora';
    font-size: 2.5rem;
}

.Gallery_title__f1tfQ h1 img{
    height: 25px;
    margin: 0 1rem;
}
.Gallery_title__f1tfQ p{
    color: #818181;
    font-family: Lora;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
}






.Gallery_slid__RL_8V{
    height: 60vh;
    width: 100%;
    margin-top: 3rem;
    position: relative;
/* margin-bottom: 1rem; */
}

.Gallery_sliderWrapper__NJ2TP{

    width: 100%;
    /* margin-top: 3rem; */
}

.Gallery_one__CICLW {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    opacity: .8;
    transform: scale(.9);
    transition: all ease .9s;
}

.Gallery_active__PSjsP{
    opacity: 1;
    transform: scale(1);

}

.Gallery_dots__QZdKS{
    height: 10px;
    width: 10px;
    background-color: black;
    border-radius: 100%;
}

.Gallery_dotActive__2i_oU{
    height: 10px;
    width: 10px;
    background-color: rgb(255, 0, 0);
    border-radius: 100%;
}


.Gallery_btn__n5KzV{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 13rem;

}
.Gallery_slick__dots__KTc5b{
    display: inline-flex !important;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.Gallery_slick__dots__KTc5b li{
    list-style: none;
    display: flex;
    /* visibility: hidden; */
}
.Gallery_slick__dots__KTc5b li button{
    outline: none;
    border: none;
    background-color: #FFC1B7;
    color: #FFC1B7;
    margin: 0 5px;
    border-radius: 100%;
    width: 11px;
    height: 11px;
    font-size: 0px;
cursor: pointer;
}
.Gallery_slick__dots__KTc5b li button:hover{
    background-color: #C45B4A;
}
.Gallery_slick__dots__KTc5b li button:focus{
    background-color: #C45B4A;
    width: 14px;
    height: 14px;
}



.Gallery_btn__n5KzV button{
 padding: 1rem;
    outline: none;
    border: none;
    background-color: #C45B4A;
    border-radius: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px 1px rgba(196,91,74,0.5);
    font-size: 1.5rem;
cursor: pointer;
}


.Gallery_btn__n5KzV button img{
    height: 20px;
    filter: invert(1);
}

.Gallery_btn__n5KzV button:first-child{
    margin-left: 6rem;
}
.Gallery_btn__n5KzV button:last-child{
    margin-right: 6rem;
}

.Gallery_sliderWrapper__NJ2TP img{
    height: 450px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
border-radius: 4px !important;
cursor: pointer;

}


.Gallery_backgroundImg__R5lnG {
    height: 80vh;
    width: 100%;
    position: relative;
  top: 6rem;
  background-color: red;
     
  }
  
  .Gallery_backgroundImg__R5lnG img{
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;


    
  }


@media screen and (max-width: 1300px){
    .Gallery_sliderWrapper__NJ2TP img{
        /* height: 400px; */
        height: 100%;
      width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
border-radius: 5px !important;
    } 

.Gallery_slid__RL_8V{
    height: 60vh;
    width: 100%;
    margin-top: 3rem;
    position: relative;
margin-bottom: 0rem;
}

.Gallery_wrapper__LGJad{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction:column;
    width:100%;
    padding-bottom: 4rem !important;   
}

}
@media screen and (max-width: 540px){

    .Gallery_sliderWrapper__NJ2TP img{
        height: 250px;
      width: 100%;
    } 

    .Gallery_wrapper__LGJad{
        display: flex;
        align-items: center;
        /* justify-content: center; */
        flex-direction:column;
        width:100%;
        padding-bottom: 1rem !important;
    }

    .Gallery_slid__RL_8V{
        height: auto;
        width: 100%;
        margin-top: 3rem;
        position: relative;
    }


    .Gallery_title__f1tfQ h1{
        font-family: 'Lora';
        font-size: 1.5rem;
    }
    
    .Gallery_title__f1tfQ h1 img{
        height: 15px;
        margin: 0 1rem;
    }
    .Gallery_title__f1tfQ p{
        color: #818181;
        font-family: Lora;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 0px;
    }
    .Gallery_btn__n5KzV{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 8rem;
    
    }
    .Gallery_btn__n5KzV button img{
        height: 10px;
        filter: invert(1);
    }

    .Gallery_btn__n5KzV button:first-child{
        margin-left: 2rem;
    }
    .Gallery_btn__n5KzV button:last-child{
        margin-right: 2rem;
    }

    .Gallery_btn__n5KzV button{
        height: 30px;
        width: 30px;
        outline: none;
        border: none;
        background-color: #C45B4A;
        border-radius: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 10px 1px rgba(196,91,74,0.5);
        font-size: 1.5rem;
    
    }

    .Gallery_backgroundImg__R5lnG {
        height: 40vh;
        width: 100%;
        margin-top: 0rem;
    top: 2rem;
      
      }
    
      .Gallery_backgroundImg__R5lnG img{
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
           object-fit: cover;
      }

}
